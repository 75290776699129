import React, { Component } from 'react'
import './HomePage.css'
import { Card, CardBody, Input } from 'reactstrap';
import shipment from '../../assets/icon/noun_shipment_3070925.png'
import invoice from '../../assets/icon/noun_invoice_250464.png'
import callIcon from '../../assets/icon/call-icon.png'
import multi from '../../assets/icon/noun_delivery_2163416.png'
import chat from '../../assets/icon/noun_chat_3359366.png'
import track from '../../assets/icon/Group 130.png'
import history from '../../assets/icon/truck.png'
import search from '../../assets/icon/new_3070925.png'
import { requestAccess, userPreferences, userProfile } from '../../api/Form'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Logo2 from '../../assets/new_logo.png';
import { image } from '../../api/URLS';
import { callActivityApi } from '../../utils/saveUserActivity';
import { phoneNoVerifyCheck } from '../../utils/utils';
import ShippingCalculatorIcon from './.././../assets/shipping-calculator-icon.svg'

export default class HomePage extends Component {
	state = {
		ADCode: '',
		creditStatus: '',
		credit: '',
		businessname: '',
		firstName: '',
		profile: '',
		accountType: undefined,
		refno: '',
		userType: '',
	}

	async componentDidMount() {
		callActivityApi({ 'pageName': 'Homescreen', 'description': 'User has visited Home Screen' });
		var userType = await localStorage.getItem('accounttypeUsers');
		this.setState({ userType: userType })
		const userId = await localStorage.getItem('userId');
		this.setState({ userId })
		userProfile({ userId })
			.then(Response => {
				this.setState({
					firstName: Response.data.data.update_profile.firstName,
					lastName: Response.data.data.update_profile.lastName,
					ADCode: Response.data.data.update_profile.ADCode,
					businessname: Response.data.data.update_profile.businessname,
					credit: Response.data.data.update_profile.credit,
					creditStatus: Response.data.data.completeProfile.creditStatus,
					email: Response.data.data.completeProfile.email,
					profile: Response.data.data.completeProfile.profile,
					accountType: Response.data.data.completeProfile.accountType || null
				})
			})
			.catch(err => {
				toast.error(err.response.data.message, {
					position: toast.POSITION.TOP_RIGHT
				});
			})

		const data = this.props.history.push
		phoneNoVerifyCheck(data)

		this.setState({ loading: true })
		const info = await localStorage.getItem('auth');
		const sendInfo = JSON.parse(info);
		const is_mobile_verified = await localStorage.getItem('is_mobile_verified')
		if (is_mobile_verified === "true") {
			userPreferences({
				userAdCode: sendInfo.ADCode,
				userId: sendInfo.id
			}).then(Response => {
				this.setState({ loading: false })
			}).catch(err => {
				this.setState({ loading: false })
				if (err.response.status === 400) {
					toast.info(err.response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
				}
			})
		}
	}

	scanParcelHandler = () => {
		this.props.history.push('./scanParcel')
	}

	scanMultiParcelHandler = () => {
		this.props.history.push('./scanMultipleParcel')
	}

	createShipmentHandler = () => {
		this.props.history.push('./createShipment')
	}
	editShipmentHandler = () => {
		this.props.history.push('./editShipment')
	}

	createMultipleShipmentsHandler = () => {
		this.props.history.push('./createMultipleShipments')
	}

	shippingHistoryHandler = () => {
		this.props.history.push('./shippingHistory')
	}

	trackShipmentsHandler = () => {
		this.props.history.push('./trackShipments')
	}

	chatHandler = () => {
		this.props.history.push('./chats')
	}

	invoiceHandler = () => {
		this.props.history.push('./invoiceByAmount')
	}

	adhocHandler = () => {
		this.props.history.push('./adhoc')
	}

	viewShipmentHandler = () => {
		this.props.history.push('./viewShipments')
	}

	userSettingsHandler = () => {
		this.props.history.push('./userSettings')
	}

	purchaseAction = () => {
		this.props.history.push('./purchaseCredits')
	}

	purchaseHistory = () => {
		this.props.history.push('./purchaseHistory')
	}

	onNext = async (accountType) => {
		const userId = await localStorage.getItem('userId');
		const token = await localStorage.getItem('token');
		userProfile({ userId, token, accountType })
			.then(Response => {
				this.setState({
					accountType: Response.data.data.accountType,
				})
			})
			.catch(err => {
				toast.error(err.response.data.message, {
					position: toast.POSITION.TOP_RIGHT
				});
			})
	}

	goToTrackingHome = () => {
		if (this.state.refno) {
			this.props.history.push({ pathname: './trackShipmentsHome', state: this.state.refno })
		} else {
			toast.error("Please enter tracking number", {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}

	requestFreeCredit = async () => {
		const userConfirmed = window.confirm('Are you sure you want free credit ?');
		if (userConfirmed) {
			this.setState({ loading: true });
			const info = await localStorage.getItem('auth');
			const sendInfo = JSON.parse(info);
			requestAccess({
				'accounttypeUsers': sendInfo.accounttypeUsers,
				'username': sendInfo.username,
				'ADCode': sendInfo.ADCode,
				'userId': sendInfo.id,
				'screenType': 'Request Free Credit',
			}).then(Response => {
				this.setState({ loading: false });
				toast.success(Response.data.message);
			}).catch(err => {
				this.setState({ loading: false });
				let message = err.message;
				if (err.response && err.response.data.message) {
					message = err.response.data.message;
				}
				toast.error(message);
				this.setState({ loading: false });
			})
		}
	}

	render() {
		const { profile, userType } = this.state
		const url = 'https://www.addressdynamic.com/api/';
		return (
			<div className="mt-1 style">
				<section className="">
					<div>
						<div className="row card-bg-styles pt-0">
							<div className="col-12">
								{
									userType !== 'Admin' &&
									<div className='mb-2 d-flex justify-content-end'>
										<button
											className="btn btn-success"
											onClick={() => this.requestFreeCredit()}
										>
											Request Free Credit
										</button>
									</div>
								}
								<Card className="dashboard-card-style shadow mb-4">
									<CardBody>
										<div className="row justify-content-center text-banner-feild">
											<div className="rounded p-1 col-12" >
												<div className="col-12 justify-content-center">
													<div className="d-flex">
														<Input
															className="form-control mr-1"
															type="text"
															placeHolder="Enter tracking number"
															onChange={(e) => this.setState({ refno: e.target.value })}
															name="name"
															value={this.state.refno}
														/>
														<button
															className="btn btn-primary"
															onClick={() => this.goToTrackingHome()}
														>
															Track
														</button>
													</div>

												</div>
											</div>
										</div>
									</CardBody>
								</Card>
								<Card className="dashboard-card-style shadow mb-4">
									<CardBody>
										<div className="row">
											<div className="col-md-1 col-sm-12 col-xs-12 p-0 p-sm-2 p-md-2 p-lg-2 pd-xl-2">
												<div className="row">
													<div className="col-2">
														{profile === null ?
															<img src={Logo2} alt="" className="dashboard-profile" />
															:
															<img src={image + '/' + profile} alt="" className="dashboard-profile" />
														}
													</div>
												</div>
											</div>
											<div className="col-md-5 col-sm-12 col-xs-12 p-0 p-sm-2 p-md-2 p-lg-2 pd-xl-2">
												<div className="row pt-2">
													<div className="col-6 pr-0">
														<h1 className="title-main-head">First Name</h1>
													</div>
													<div className="col-6 ">
														<h1 className="title-description-profile">{this.state.firstName}</h1>
													</div>
												</div>
												<div className="row pt-2">
													<div className="col-6 pr-0">
														<h1 className="title-main-head">Last Name</h1>
													</div>
													<div className="col-6 ">
														<h1 className="title-description-profile">{this.state.lastName}</h1>
													</div>
												</div>
												<div className="row pt-2">
													<div className="col-6 pr-0">
														<h1 className="title-main-head">Business Name</h1>
													</div>
													<div className="col-6 ">
														<h1 className="title-description-profile">{this.state.businessname}</h1>
													</div>
												</div>
											</div>
											<div className="col-md-6 col-sm-12 col-xs-12 p-0 p-sm-2 p-md-2 p-lg-2 pd-xl-2">
												<div className="row pt-2">
													<div className="col-6 pr-0">
														<h1 className="title-main-head">AD Code</h1>
													</div>
													<div className="col-6 ">
														<h1 className="title-description-profile">{this.state.ADCode}</h1>
													</div>
												</div>
												<div className="row pt-2">
													<div className="col-6 pr-0">
														<h1 className="title-main-head">Credit Category</h1>
													</div>
													<div className="col-6 ">
														<h1 className="title-description-profile">{this.state.creditStatus}</h1>
													</div>
												</div>
												<div className="row pt-2">
													<div className="col-6 pr-0">
														<h1 className="title-main-head">Credit</h1>
													</div>
													<div className="col-6" >
														<h1 className="title-description-profile">{this.state.credit}</h1>
													</div>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.scanParcelHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={shipment} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">Scan Parcel</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.scanMultiParcelHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={shipment} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">Update Multiple Parcels</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.createShipmentHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={shipment} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">Create Shipment</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.editShipmentHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={shipment} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">Edit Shipment</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.createMultipleShipmentsHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={multi} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">Create Multiple Shipments</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.shippingHistoryHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={history} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">Shipping History</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.trackShipmentsHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={track} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">Track Shipments</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.chatHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={callIcon} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">Call</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.chatHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={chat} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">Chats</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.invoiceHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={invoice} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">Invoice</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.adhocHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={invoice} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">Print Label</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.viewShipmentHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={search} alt="" />
											</div>
											<p className="text-center card-title-style pt-4">View Shipment</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
								onClick={this.userSettingsHandler}>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<icon class="fa fa-cog icon_size50" />
											</div>
											<p className="text-center card-title-style pt-4">User Settings</p>
										</div>
									</CardBody>
								</Card>
							</div>
							<Link to="/admin/shipping-calculator" className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
							>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<img src={ShippingCalculatorIcon} alt='Shipping Calculator' class="fa fa-cog icon_size50" />
											</div>
											<p className="text-center card-title-style pt-4">Shipping Calculator</p>
										</div>
									</CardBody>
								</Card>
							</Link>
							<Link to="/admin/cipl" className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-4 home-card-style"
							>
								<Card className="card-style-1 shadow">
									<CardBody className="d-flex justify-content-center">
										<div className="pt-3">
											<div className="d-flex justify-content-center">
												<i className="fa fa-file-text-o" style={{ fontSize: "80px", color: "blue" }}></i>
											</div>
											<p className="text-center card-title-style pt-4">CIPL</p>
										</div>
									</CardBody>
								</Card>
							</Link>
						</div>
					</div>
				</section>
				<div className="d-flex justify-content-center">
					<Link to="/admin/help" className="help-text-style">Help</Link>
				</div>

			</div>
		)
	}
}