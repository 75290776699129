const {
  REACT_APP_URL,
  REACT_APP_PAYPAL_SANDBOX,
  REACT_APP_PAYPAL_CLIENT_ID,
  REACT_APP_PAYPAL_SECRET_CODE,
  REACT_APP_PAYPAL_SANDBOX_CLIENT_ID,
  REACT_APP_PAYPAL_SANDBOX_SECRET_CODE,
} = process.env;
const BASE_URL = REACT_APP_URL
  ? REACT_APP_URL
  : "https://www.addressdynamic.com/api";
const SOCKET_URL = "https://api.addressdynamic.com/";
//const SOCKET_URL = 'http://198.12.251.20:3000/';
export const image = REACT_APP_URL
  ? REACT_APP_URL
  : "https://www.addressdynamic.com/api/";
export const paypal_sandbox_enable = REACT_APP_PAYPAL_SANDBOX;
export const paypal_client_id = REACT_APP_PAYPAL_CLIENT_ID;
export const paypal_secret_code = REACT_APP_PAYPAL_SECRET_CODE;
export const paypal_sandbox_client_id = REACT_APP_PAYPAL_SANDBOX_CLIENT_ID;
export const paypal_sandbox_secret_code = REACT_APP_PAYPAL_SANDBOX_SECRET_CODE;

export const URLS = {
  SOCKET_URL,
  AUTH: BASE_URL + "/login.php",
  ERRAND_REQUEST: BASE_URL + "/v2/request-errand.php",
  PROFILE: BASE_URL + "/profile.new.php",
  SIGNUP: BASE_URL + "/signup.php",
  VERIFY_PHONE: BASE_URL + "/verify-phone.php",
  OTP: BASE_URL + "/send-otp.php",
  REST_PASSWORD: BASE_URL + "/reset-request.php",
  CREATE_PASSWORD: BASE_URL + "/reset-password.php",

  LOST_PARCEL: BASE_URL + "/lost-parcel.php",
  LOST_PARCEL_SUCCESS: BASE_URL + "/lost-parcel-success.php",

  ADD_ADDRESS: BASE_URL + "/address/add.php",
  ADDRESS_HISTORY: BASE_URL + "/address/history.php",

  SCAN_PARCEL: BASE_URL + "/scan-parcel.php",
  SCAN_MULTIPLE_PARCEL: BASE_URL + "/scan-multiple-parcel.php",

  CREDIT_PURCHASE: BASE_URL + "/credit-purchase.php",
  REQUEST_ACCESS: BASE_URL + "/v2/request-access.php",
  SHIPMENT: BASE_URL + "/create-shipment.new.php",
  SHIPMENT_MULTIPLE: BASE_URL + "/create-multiple-shipment.new.php",
  SHIPMENT_MULTIPLE_UPDATED: BASE_URL + "/v2/create-multiple-shipment.php",

  GET_SHIPMENT: BASE_URL + "/get-shipment.php",

  SR_REQUEST: BASE_URL + "/srRequest.php",
  HELP: BASE_URL + "/help.php",

  SHIPPING_HISTORY: BASE_URL + "/shipment-history.php",
  TRACK_SHIPMENT: BASE_URL + "/tracking.php",
  PACKAGE_HANDLER: BASE_URL + "/package-handler.php",
  INCOMING_SHIPMENT: BASE_URL + "/incoming-shipments.php?deviceAdCode=adcode",
  OUTGOING_SHIPMENT: BASE_URL + "/outgoing-shipments.php?deviceAdCode=adcode",

  COURIER_DASHBOARD: BASE_URL + "/courier-dashboard.php",

  SEARCH: BASE_URL + "/chat/user-search.php",
  CHAT_REQUEST: BASE_URL + "/chat/chat-request.php",
  CONFIRM_REQUEST: BASE_URL + "/chat/confirm-request.php",
  CHAT_LIST: BASE_URL + "/chat/chat-list.php",
  SEND_CHAT: BASE_URL + "/chat/send-chat.php",
  READ_CHAT: BASE_URL + "/chat/chat-read.php",
  CHAT_MESSAGES_LIST: BASE_URL + "/chat/chat-messages.php",
  DELETE_CHAT: BASE_URL + "/chat/delete-chat.php",
  DELETE_MESSAGE: BASE_URL + "/chat/delete-message.php",
  CALL: BASE_URL + "/call.php",

  UPDATE_CHAT: BASE_URL + "update/chat",
  CONTACTS: BASE_URL + "/contact/list",

  ADD_CALL: BASE_URL + "/call/add.php",
  UPDATE_CALL: BASE_URL + "/call/update.php",
  CALL_HISTORY: BASE_URL + "/call/history.php",
};

export const URLS_NEW = {
  APIREQ: BASE_URL + "/apiRequest.php",
  GET_PRICE_LIST: BASE_URL + "/get-pricelist.php",
  UPDATE_PRICE_LIST: BASE_URL + "/update-pricelist.php",
  GET_API_CLIENT_LIST: BASE_URL + "/get-apiclientlist.php",
  GET_API_CLIENT_DETAIL: BASE_URL + "/get-apiclientdetail.php",
  GRANT_ACCESS: BASE_URL + "/grant-apiaccess.php",
  VALIDATE_REF_NUM: BASE_URL + "/v2/validate-refnum.php",
  VALIDATE_MANIFEST_NUM: BASE_URL + "/v2/get-master-number-mot.php",
  SCAN_MULTIPLE_PARCEL: BASE_URL + "/v2/scan-multiple-parcel.php",
  EDIT_SHIPMENT: BASE_URL + "/v2/edit-shipment.php",
  CREATE_SHIPMENT: BASE_URL + "/v2/create-shipment.php",
  GET_SCREEN_ACCESS_REQUESTS: BASE_URL + "/v2/get-screenaccess-requests.php",
  UPDATE_SCREEN_ACCESS: BASE_URL + "/v2/update-screen-access.php",
  CHECK_SCREEN_ACCESS: BASE_URL + "/v2/check-screen-access.php",
  GET_ALL_RIDERS: BASE_URL + "/v2/get-allriders.php",
  ASSIGN_MASTER_NUMBER: BASE_URL + "/v2/assign-master-number.php",
  SAVE_FLIGHT_DETAILS: BASE_URL + "/v2/assign-flight-details.php",
  UPDATE_STATUS_AIRWAY: BASE_URL + "/v2/update-status-airway.php",
  LOOKUP_AIRWAY: BASE_URL + "/v2/lookup-airway.php",
  GET_MANIFEST_DETAILS: BASE_URL + "/v2/get-manifest-detail.php",
  GET_MULTIPLE_SHIPMENTS: BASE_URL + "/v2/get-multiple-shipments.php",
  GET_CONSIGNEE_DETAILS: BASE_URL + "/v2/get-consignee-details.php",
  GET_LAST_SHIPMENT: BASE_URL + "/v2/get-last-shipment.php",
  SEARCH_REQUESTS: BASE_URL + "/v2/search-requests.php",
  SAVE_EXTERNAL_REFS: BASE_URL + "/v2/save-external-refs.php",
  SAVE_EXTERNAL_REFS_UNSASSIGN:
    BASE_URL + "/v2/save-external-refs-unassign.php",
  EXTERNAL_TRACKING_LIST: BASE_URL + "/v2/external-tracking-list.php",
  GET_EXTERNAL_TRACKING_NUMBER_DETAIL:
    BASE_URL + "/v2/get-external-tracking-detail.php",
  UPDATE_EXTERNAL_REFS: BASE_URL + "/v2/update-external-tracking.php",
  DELETE_EXTERNAL_REFS: BASE_URL + "/v2/delete-external-tracking.php",
  SEARCH_EXTERNAL_REFS: BASE_URL + "/v2/search-external-tracking.php",
  VALIDATE_EXT_NUM: BASE_URL + "/v2/validate-tracking-num.php",
  EXTERNAL_TRACKING: BASE_URL + "/v2/external-tracking.php",
  GET_SHIPMENTS_FILTER_WISE: BASE_URL + "/v2/get-shipments-byfilter.php",
  SAVE_USER_ACTIVITY: BASE_URL + "/v2/save-activity-log.php",
  GET_USER_ACTIVITIES: BASE_URL + "/v2/get-user-activities.php",
  VALIDATE_USER_ADCODES: BASE_URL + "/v2/validate-adCodes.php",
  SHIPPING_DOCS: BASE_URL + "/v2/shipping-docs.php",
  UPDATE_PROFILE: BASE_URL + "/v2/update-user-profile.php",
  UPDATE_EXTERNAL_NOTIFICATION_STATUS:
    BASE_URL + "/v2/update-notifications-external-tracking.php",
  SEARCH_USER_BY_ADCODE: BASE_URL + "/v2/search-user-by-adcode.php",
  ADD_CREDITS: BASE_URL + "/v2/add-credits.php",
  SEND_BULK_MESSAGES: BASE_URL + "/v2/send-bulk-messages.php",
  CUSTOM_TRACKING: BASE_URL + "/v2/custom-tracking.php",
  SEARCH_SHIPMENT_INVOICE: BASE_URL + "/v2/search-shipment-invoice.php",
  UPDATE_SHIPMENT_INVOICE: BASE_URL + "/v2/update-invoice.php",
  SEND_INVOICE: BASE_URL + "/v2/send-invoice.php",
  SAVE_USER_PREFRENCES: BASE_URL + "/v2/user-preferences.php",
  GET_USER_PREFRENCES: BASE_URL + "/v2/get-user-preferences.php",
  GET_REPORTS: BASE_URL + "/v2/get-reports.php",
  CREATE_PAYMENT: BASE_URL + "/v2/create-payment.php",
  UPDATE_CREDIT_UNITS: BASE_URL + "/v2/update-credit-units.php",
  GET_CREDIT_UNITS: BASE_URL + "/v2/get-credit-units.php",
  PAYMENT_HISTORY: BASE_URL + "/v2/paypal-history.php",
  API_REPORTS: BASE_URL + "/v2/api-hits.php",
  INVOICE_REPORTS: BASE_URL + "/v2/get-invoices.php",
  ADHOC_TEMPLATE_LIST: BASE_URL + "/v2/get-adhoc-invoices.php",
  CREATE_ADHOC_TEMPLATE: BASE_URL + "/v2/create-adhoc-invoice-template.php",
  UPDATE_ADHOC_TEMPLATE: BASE_URL + "/v2/update-adhoc-invoice-template.php",
  DELETE_ADHOC_TEMPLATE: BASE_URL + "/v2/delete-adhoc-invoice.php",
  CHECK_AD_CODE: BASE_URL + "/v2/check-ad_code-adhoc-invoice.php",
  CHECK_TRACK_NUMBER: BASE_URL + "/v2/check-track-number-adhoc-invoice.php",
  GENERATE_ADHOC_INVOICE: BASE_URL + "/v2/generate-adhoc-invoice.php",
  CHECK_UNIQUE_NUMBER: BASE_URL + "/v2/check-ad_code-adhoc-invoice.php",
  TRACKING_MORE_DATA: BASE_URL + "/v2/tracking-more-api.php",
  COURIERS_ALL_LIST: BASE_URL + "/v2/get-couriers-list.php", //get all couriers list
  SAVE_TRACKING_NUMBER: BASE_URL + "/v2/save-tracking-number.php",
  GET_TRACKING_NUMBER: BASE_URL + "/v2/get-tracking-numbers.php",
  DELETE_TRACKING_NUMBER: BASE_URL + "/v2/delete-tracking-number.php",
  COUNT_ACCESS_PENDING_REQUEST:
    BASE_URL + "/v2/count-pending-access-requests.php",
  GET_IP_ADDRESSES: BASE_URL + "/v2/get-ip-addresses.php",
  SAVE_IP_ADDRESSES: BASE_URL + "/v2/save-ip-address.php",
  DELETE_SHIPMENT: BASE_URL + "/v2/delete-shipment.php",
  GET_DELETE_SHIPMENT: BASE_URL + "/v2/get-deleted-shipments.php",
  REINSTATE_SHIPMENT: BASE_URL + "/v2/reinstate-shipment.php",
  SEND_EMAIL_OTP: BASE_URL + "/v2/send-email-otp.php",
  VERIFY_EMAIL_OTP: BASE_URL + "/v2/verify-email-otp.php",
  GET_ALL_USERS: BASE_URL + "/v2/get-users.php",
  ADMIN_USER_NO_VERIFY_REQUEST: BASE_URL + "/v2/admin-user-requests.php",
  LOCK_USER_ACCOUNT: BASE_URL + "/v2/lock-user.php",
  DELETE_USER_ACCOUNT: BASE_URL + "/v2/delete-unverified-user.php",


  SEARCH_SHIPMENT_BILL: BASE_URL + "/v2/get-bill-template.php",
  CREATE_SHIPMENT_BILL: BASE_URL + "/v2/generate-bill-template.php",
  REQUEST_DEMO: BASE_URL + "/v2/request-demo-quote.php",
  GET_BANNER_SLIDES: BASE_URL + "/v2/get-banner-slides.php",
  UPDATE_BANNER_SLIDES: BASE_URL + "/v2/update-banner-slides.php",

  // cipl
  CIPL_HEADERS: BASE_URL + "/v2/cipl/cipl-header.php",
  CIPL_PRODUCT_DB: BASE_URL + "/v2/cipl/product-database.php",
  CIPL_BILL: BASE_URL + "/v2/cipl/cipl-bill.php",
  CIPL_EMAIL: BASE_URL + "/v2/cipl/send-cipl-email.php",
  CIPL_ASSIGN_REF: BASE_URL + "/v2/cipl/assign-to-ref.php",
  CIPL_DOWNLOAD: BASE_URL + "/v2/cipl/download-cipl-email.php",
};
