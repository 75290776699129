import React, { useState, useEffect } from "react";
import Select from "react-select"; // Import React Select
import { ciplProductDb } from "../../../../api/Form";
import { useField, useFormikContext } from "formik";

const CustomInputDropdown = ({ name, codeName, codeDisable }) => {
    const [options, setOptions] = useState([]); // Dropdown options
    const [inputValue, setInputValue] = useState(""); // Current input value for search

    // Formik hooks
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const searchCategoryItems = async (searchText) => {
        if (searchText?.length < 3) {
            setOptions([]);
            return;
        }

        const deviceADcode = localStorage.getItem("adCodes");
        const userId = localStorage.getItem("userId");
        const payload = { userId, deviceADcode, searchText, mode: "get-all-category-items" };

        try {
            const response = await ciplProductDb(payload);
            if (response?.data?.data) {
                setOptions(
                    response.data.data.map((item) => ({
                        label: item.name, // React-select uses 'label' for display text
                        value: item.code, // React-select uses 'value' for the actual value
                    }))
                );
            } else {
                setOptions([]);
            }
        } catch (err) {
            setOptions([]);
        }
    };

    const handleInputChange = (newValue) => {
        if (newValue.trim() === "" || newValue.length > 100) {
            return;
        }
        setFieldValue(codeDisable, false);
        setInputValue(newValue); // Update local input value
        setFieldValue(name, newValue || "");
        if (newValue.length > 2) {
            searchCategoryItems(newValue); // Fetch options if input length > 2
        }
    };

    const handleOptionSelect = (selectedOption) => {
        if (!selectedOption) {
            setFieldValue(codeDisable, false);
        } else {
            setFieldValue(codeDisable, true);
        }
        setFieldValue(name, selectedOption?.label || "");
        setFieldValue(codeName, selectedOption?.value || "");
    };


    return (
        <div style={{ width: "180px" }} className="position-relative">
            <Select
                options={options}
                onInputChange={handleInputChange}
                onChange={handleOptionSelect}
                value={
                    field.value
                        ? { label: field.value, value: field.value }
                        : null
                } // Sync with Formik field
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                placeholder="Search or add"
                isClearable
                menuPortalTarget={document.body} // Render dropdown outside parent
                styles={{
                    menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999, // Ensure dropdown appears above other elements
                    }),
                    menu: (provided) => ({
                        ...provided,
                        maxHeight: 300,
                        overflowY: "auto", // Add scroll if options exceed height
                    }),
                }}
            />
            {/* Error message if field is invalid */}
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
        </div>
    );
};

export default CustomInputDropdown;
