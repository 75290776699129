import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { checkScreenAccess, ciplProductDb, requestAccess } from "../../../api/Form";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";

class CreateProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access: false,
            editMode: false,
            categoryName: "",
            categoryId: null,
            products: [],
            userIdFromProduct: null,
            isGlobalAccess: false,
            globalAccessPopup: false,
            productToAdd: { name: "", code: "" },
            duplicateProductIndex: null
        };
    }

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem("auth"));
        if (userInfo && userInfo.accounttypeUsers !== "Admin") {
            const screenAccessPayload = {
                userId: userInfo.id,
                screenType: "CIPL Product Database",
            };
            checkScreenAccess(screenAccessPayload)
                .then(() => {
                    this.setState({ access: true });
                    const params = new URLSearchParams(window.location.search);
                    const categoryIdFromUrl = params.get("category-id");
                    if (categoryIdFromUrl) {
                        this.setState({ editMode: true, categoryId: categoryIdFromUrl });
                        this.fetchCategoryData(categoryIdFromUrl);
                    }
                })
                .catch(() => this.setState({ access: false }));
        }
        else {
            this.setState({ access: true });
            const params = new URLSearchParams(window.location.search);
            const categoryIdFromUrl = params.get("category-id");
            if (categoryIdFromUrl) {
                this.setState({ editMode: true, categoryId: categoryIdFromUrl });
                this.fetchCategoryData(categoryIdFromUrl);
            }
        }
    }

    fetchCategoryData = async (categoryId) => {
        const deviceADcode = localStorage.getItem("adCodes");
        const userId = localStorage.getItem("userId");
        const getProductsPayload = {
            deviceADcode,
            userId,
            mode: "edit-product-list",
            id: categoryId,
        };

        try {
            const response = await ciplProductDb(getProductsPayload)
            if (response?.data) {
                this.setState({
                    categoryName: response.data.data.categoryName,
                    products: JSON.parse(response.data.data.items),
                    userIdFromProduct: response.data.data.userId,
                });
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || "An unexpected error occurred.");
        }
    }

    handleSave = async (values, isGlobalSave) => {
        const deviceADcode = localStorage.getItem("adCodes");
        const userId = localStorage.getItem("userId");
        const payload = {
            deviceADcode,
            userId: this.state.editMode ? this.state.userIdFromProduct : userId,
            mode: this.state.editMode ? "update-product-list" : "create-product-list",
            categoryName: values.categoryName,
            items: values.products,
            isGlobalSave,
            id: this.state.editMode ? this.state.categoryId : null,
        };

        let response;
        try {
            response = await ciplProductDb(payload);

            if (response?.data?.data) {
                toast.success(response.data.message);
                this.setState({ duplicateProductIndex: null })
                const { location, history } = this.props;
                const basePath = location.pathname.split("/").slice(0, -1).join("/");
                setTimeout(() => history.push(`${basePath}/product-database`), 1500);
            }
        } catch (err) {
            // Safely access the error message
            const errorMessage = err?.response?.data?.message || "An unexpected error occurred.";
            const duplicateIndex = err?.response?.data?.data?.duplicate;
            if (duplicateIndex)
                this.setState({ duplicateProductIndex: duplicateIndex })
            else this.setState({ duplicateProductIndex: null })
            // Display the error message using toast
            toast.error(errorMessage);
        }

    };

    closeGlobalAccessPopup = () => {
        this.setState({ globalAccessPopup: false })
    }

    checkGlobalaccess = async () => {
        const userInfo = JSON.parse(localStorage.getItem('auth'));

        if (userInfo && userInfo.accounttypeUsers === "Admin") {
            this.setState({ isGlobalAccess: true });
            return Promise.resolve(true); // Returns a resolved promise
        } else if (userInfo && userInfo.accounttypeUsers !== "Admin") {
            const screenAccessPayload = {
                userId: userInfo.id,
                screenType: "Product Global Save"
            };

            // Return the promise chain
            return checkScreenAccess(screenAccessPayload)
                .then((response) => {
                    this.setState({ isGlobalAccess: true });
                    return true;
                })
                .catch((error) => {
                    this.setState({ isGlobalAccess: false });
                    return false;
                });
        } else {
            return Promise.resolve(false); // Handle case where userInfo is null
        }
    };


    sendRequestAccess = async () => {
        const info = localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        requestAccess({
            'username': sendInfo.username,
            'firstName': sendInfo.firstName,
            'lastName': sendInfo.lastName,
            'accounttypeUsers': sendInfo.accounttypeUsers,
            'businessname': sendInfo.businessname,
            'ADCode': sendInfo.ADCode,
            'address': sendInfo.address,
            'email': sendInfo.email,
            'phoneNumber': sendInfo.phonenumber,
            'userId': sendInfo.id,
            'screenType': 'CIPL Product Database',
        }).then(Response => {
            toast.success(Response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(err => {
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    };

    requestGlobalAccess = () => {
        const info = localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        requestAccess({
            'username': sendInfo.username,
            'firstName': sendInfo.firstName,
            'lastName': sendInfo.lastName,
            'accounttypeUsers': sendInfo.accounttypeUsers,
            'businessname': sendInfo.businessname,
            'ADCode': sendInfo.ADCode,
            'address': sendInfo.address,
            'email': sendInfo.email,
            'phoneNumber': sendInfo.phonenumber,
            'userId': sendInfo.id,
            'screenType': 'Product Global Save',
        }).then(Response => {
            toast.success(Response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            this.closeGlobalAccessPopup();
        }).catch(err => {
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
            this.closeGlobalAccessPopup();
        })
    }

    isDuplicacyInProducts = (products, productToAdd) => {
        const duplicateProduct = products.find(
            (product) => String(product.name) === String(productToAdd.name) && String(product.code) === String(productToAdd.code))
        if (duplicateProduct) {
            toast.error(`Duplicate product found with name ${duplicateProduct.name}`);
            return true;
        }
        return false;
    }

    render() {
        const { access, editMode, categoryName, products, productToAdd } = this.state;

        // Validation schema
        const validationSchema = Yup.object().shape({
            categoryName: Yup.string().required("Category name is required"),
            products: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.string().required("Product name is required"),
                        code: Yup.string()
                            .matches(/^[0-9-]+$/, "Only numbers and hyphens are allowed")
                    })
                ),
            productToAdd: Yup.object().shape({
                code: Yup.string()
                    .matches(/^[0-9-]+$/, "Only numbers and hyphens are allowed in HS Code")
            })
        });

        return (
            <>
                <Modal isOpen={this.state.globalAccessPopup} toggle={this.closeGlobalAccessPopup}>
                    <ModalHeader>Are you sure?</ModalHeader>
                    <ModalBody>
                        <div className="row d-flex justify-content-center py-2 px-4">
                            You do not have the global access. Do you want to request for the access now?
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.requestGlobalAccess}>Yes</Button>
                        <Button color="secondary" onClick={this.closeGlobalAccessPopup}>No</Button>
                    </ModalFooter>
                </Modal>
                {!access ? (
                    <div className="justify-content-center pt-2 main-contain-title">
                        <p>This feature is restricted.</p>
                        <Button onClick={() => this.sendRequestAccess()}>
                            Request Access for free
                        </Button>
                    </div>
                ) : (
                    <div className="w-100 px-0 py-2">
                        <Card>
                            <CardHeader
                                onClick={() =>
                                    this.props.history.push(
                                        this.props.location.pathname.split("/").slice(0, -1).join("/") + "/product-database"
                                    )
                                }
                                className="c-header"
                            >
                                Product Database
                            </CardHeader>
                            <CardBody>
                                <div className="d-flex justify-content-between">
                                    <h4 className="mt-3">{editMode ? "Edit" : "Create"} Products List</h4>
                                    <button
                                        style={{ color: "white", height: "40px", background: "var(--main-bg-color-container-header)" }}
                                        onClick={() => this.props.history.goBack()}
                                        className="rounded px-4"
                                    >
                                        Back
                                    </button>
                                </div>
                                <Formik
                                    enableReinitialize
                                    initialValues={{ categoryName, products, productToAdd }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        if (values.products.length === 0) {
                                            toast.error("At least one product is required to create product list")
                                        }
                                        this.handleSave(values, 0); // 0 for normal save
                                        setSubmitting(false);
                                    }}
                                >
                                    {({ values, errors, touched, setFieldTouched, validateForm, setFieldValue, handleSubmit }) => (
                                        <Form>
                                            <div className="mb-3">
                                                <label>Category Name</label>
                                                <Field
                                                    type="text"
                                                    name="categoryName"
                                                    className={`form-control ${errors.categoryName && touched.categoryName ? "is-invalid" : ""
                                                        }`}
                                                    placeholder="Category name"
                                                />
                                                {errors.categoryName && touched.categoryName && (
                                                    <div className="invalid-feedback">{errors.categoryName}</div>
                                                )}
                                            </div>

                                            <FieldArray name="products">
                                                {({ remove, push }) => (
                                                    <>
                                                        {values.products.map((product, index) => (
                                                            <div
                                                                className="row mb-3 d-flex align-items-top"
                                                                key={`product-${product}`}
                                                            >
                                                                <div className="col-md-6">
                                                                    <label>Item Name/Description</label>
                                                                    <Field
                                                                        maxLength="100"
                                                                        name={`products.${index}.name`}
                                                                        className={`form-control ${(errors.products &&
                                                                            errors.products[index] &&
                                                                            errors.products[index].name &&
                                                                            touched.products &&
                                                                            touched.products[index] &&
                                                                            touched.products[index].name) || index === this.state.duplicateProductIndex
                                                                            ? "is-invalid"
                                                                            : ""
                                                                            }`}
                                                                        placeholder="Product Name"
                                                                    />
                                                                    {errors.products &&
                                                                        errors.products[index] &&
                                                                        errors.products[index].name &&
                                                                        touched.products &&
                                                                        touched.products[index] &&
                                                                        touched.products[index].name && (
                                                                            <div className="invalid-feedback">
                                                                                {errors.products[index].name}
                                                                            </div>
                                                                        )}
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>HS Code/ Tariff No</label>
                                                                    <Field
                                                                        maxLength="15"
                                                                        name={`products.${index}.code`}
                                                                        className={`form-control ${(errors.products &&
                                                                            errors.products[index] &&
                                                                            errors.products[index].code &&
                                                                            touched.products &&
                                                                            touched.products[index] &&
                                                                            touched.products[index].code) || index === this.state.duplicateProductIndex
                                                                            ? "is-invalid"
                                                                            : ""
                                                                            }`}
                                                                        placeholder="HS Code"
                                                                    />
                                                                    {errors.products &&
                                                                        errors.products[index] &&
                                                                        errors.products[index].code &&
                                                                        touched.products &&
                                                                        touched.products[index] &&
                                                                        touched.products[index].code && (
                                                                            <div className="invalid-feedback">
                                                                                {errors.products[index].code}
                                                                            </div>
                                                                        )}
                                                                </div>
                                                                <button
                                                                    style={{ height: "40px", marginTop: "30px", width: "110px" }}
                                                                    type="button"
                                                                    className="btn btn-danger"
                                                                    onClick={() => {
                                                                        if (values.products.length === 1) {
                                                                            toast.error("At least one product is required");
                                                                            return;
                                                                        }
                                                                        remove(index);
                                                                    }}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        ))}
                                                        <div className="row mb-3 d-flex align-items-top">
                                                            <div className="col-md-6">
                                                                <label>Item Name/Description</label>
                                                                <Field
                                                                    maxLength="100"
                                                                    name={`productToAdd.name`}
                                                                    className={`form-control ${errors.products &&
                                                                        errors.productToAdd &&
                                                                        errors.productToAdd.name &&
                                                                        touched.products &&
                                                                        touched.productToAdd &&
                                                                        touched.productToAdd.name
                                                                        ? "is-invalid"
                                                                        : ""
                                                                        }`}
                                                                    placeholder="Product Name"
                                                                />
                                                                {
                                                                    errors.productToAdd?.name &&
                                                                    touched.productToAdd?.name && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.productToAdd.name}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>HS Code/ Tariff No</label>
                                                                <Field
                                                                    maxLength="15"
                                                                    name={`productToAdd.code`}
                                                                    className="form-control"
                                                                    placeholder="HS Code"
                                                                />
                                                                {
                                                                    errors.productToAdd?.code &&
                                                                    touched.productToAdd?.code && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.productToAdd?.code}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <button
                                                                style={{ height: "40px", marginTop: "30px", width: "110px" }}
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={async () => {
                                                                    const duplicacyFoundInProducts = this.isDuplicacyInProducts(values.products, values.productToAdd);
                                                                    if (duplicacyFoundInProducts)
                                                                        return
                                                                    const errors = await validateForm();
                                                                    const hscodeErr = errors?.productToAdd?.code;
                                                                    if (hscodeErr) {
                                                                        toast.error(hscodeErr)
                                                                        return;
                                                                    }
                                                                    const { productToAdd } = values;
                                                                    if (productToAdd.name.trim() === "") {
                                                                        toast.error("Cannot add product with empty description");
                                                                        return;
                                                                    }
                                                                    push({ name: productToAdd.name, code: productToAdd.code });
                                                                    setFieldValue("productToAdd", { name: "", code: "" })
                                                                }}
                                                            >
                                                                + Add Item
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </FieldArray>

                                            <div className="d-flex justify-content-end w-100 my-3">
                                                <Button
                                                    style={{ background: "var(--main-bg-color-container-header)" }}
                                                    color="primary"
                                                    type="submit"
                                                    className="me-3 mr-3"
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    style={{ background: "var(--main-bg-color-container-header)" }}
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => {
                                                        validateForm().then((formErrors) => {
                                                            if (Object.keys(formErrors).length === 0) {
                                                                this.checkGlobalaccess().then((hasAccess) => {
                                                                    if (hasAccess) {
                                                                        this.handleSave(values, 1)
                                                                    } else {
                                                                        this.setState({ globalAccessPopup: true })
                                                                    }
                                                                });


                                                                // this.handleSave(values, 1); // Global save
                                                                // this.checkGlobalaccess() ? this.handleSave(values, 1) : this.setState({ globalAccessPopup: true })
                                                            } else {
                                                                Object.keys(formErrors).forEach((field) => {
                                                                    if (field === "products") {
                                                                        // Specifically handle product errors
                                                                        values.products.forEach((_, index) => {
                                                                            setFieldTouched(`products.${index}.name`, true, true);
                                                                        });
                                                                    } else {
                                                                        setFieldTouched(field, true, true);
                                                                    }
                                                                }
                                                                );
                                                            }
                                                        });
                                                    }}
                                                >
                                                    Save Global
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </div>
                )}
            </>
        );
    }
}

export default withRouter(CreateProductList);