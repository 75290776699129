import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { checkScreenAccess, ciplProductDb, requestAccess } from "../../../api/Form";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      currentCategory: null,
      showDeleteWarning: false,
      userData: null,
      access: false
    };
  }

  componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('auth'));
    if (userInfo && userInfo.accounttypeUsers !== "Admin") {
      const screenAccessPayload = {
        userId: userInfo.id,
        screenType: "CIPL Product Database"
      }
      const checkProductDbAccess = () => {
        checkScreenAccess(screenAccessPayload).then((response) => {
          this.setState({ access: true });
          this.fetchCategories();
        }).catch((error) => { this.setState({ access: false }); })
      }
      checkProductDbAccess();
    }
    else {
      this.setState({ access: true });
      this.fetchCategories();
    }
  }

  sendRequestAccess = async () => {
    const info = localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);
    requestAccess({
      'username': sendInfo.username,
      'firstName': sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers': sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode': sendInfo.ADCode,
      'address': sendInfo.address,
      'email': sendInfo.email,
      'phoneNumber': sendInfo.phonenumber,
      'userId': sendInfo.id,
      'screenType': 'CIPL Product Database',
    }).then(Response => {
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  };

  fetchCategories = async () => {
    const deviceADcode = localStorage.getItem('adCodes');
    const userId = localStorage.getItem('userId');
    const getCategoriesPayload = { deviceADcode, userId, mode: 'get-product-database-list' };

    try {
      const response = await ciplProductDb(getCategoriesPayload)
      if (response?.data)
        this.setState({ categories: response.data.data });
    }
    catch (error) {
    };
  };

  closeDeleteWarning = () => {
    this.setState({ showDeleteWarning: false });
  }
  deleteCategory = async () => {
    const deviceADcode = localStorage.getItem('adCodes');
    const userId = localStorage.getItem('userId');
    const deleteCategoryPayload = {
      mode: "delete-product-list",
      id: this.state.currentCategory.id,
      deviceADcode,
      userId
    };
    try {
      const response = await ciplProductDb(deleteCategoryPayload);
      if (response?.data) {
        this.fetchCategories(); // Refresh category list
        toast.success('Product list deleted successfully!');
      }
    }
    catch (error) {
      toast.error(error.message);
    }
    finally {
      this.closeDeleteWarning();
    };
  }

  requestGlobalAccess = () => {
    const info = localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);
    requestAccess({
      'username': sendInfo.username,
      'firstName': sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers': sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode': sendInfo.ADCode,
      'address': sendInfo.address,
      'email': sendInfo.email,
      'phoneNumber': sendInfo.phonenumber,
      'userId': sendInfo.id,
      'screenType': 'Product Global Save',
    }).then(Response => {
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  render() {

    const { location, history } = this.props;

    const basePath = location.pathname.split('/').slice(0, -1).join('/');

    const redirectPathForProduct = `${basePath}/product-list?category-id=`;
    const redirectPathForAddList = `${basePath}/create-product-list`;
    const redirectPathForEditList = `${basePath}/create-product-list?category-id=`;

    const userInfo = JSON.parse(localStorage.getItem('auth'));
    const isUserAdmin = userInfo?.accounttypeUsers === "Admin";


    return (
      <>
        {!this.state.access ? <div className="justify-content-center pt-2 main-contain-title">
          <>
            <p>This feature is restricted.</p>
            <Button
              onClick={() => this.sendRequestAccess()}
            >Request Access for free</Button>
          </>
        </div>
          :
          <>
            <Modal isOpen={this.state.showDeleteWarning} toggle={this.closeDeleteWarning}>
              <ModalHeader>Are you sure?</ModalHeader>
              <ModalBody>
                <div className="row d-flex justify-content-center py-2 px-4">
                  This action can not be reversed. This category will be permamemtly deleted. Do you want to delete?
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={this.deleteCategory}>Delete</Button>
                <Button color="secondary" onClick={this.closeDeleteWarning}>Cancel</Button>
              </ModalFooter>
            </Modal>
            <div className='w-100 px-0 py-2'>
              <Card>
                <CardHeader className='c-header'>Product Database</CardHeader>
                <CardBody>
                  <div className="d-flex justify-content-between w-100 my-3 flex-column flex-sm-row">
                    <h4 className="ml-3 text-nowrap">Product List</h4>
                    <div className="d-flex justify-content-end w-100">
                      {!isUserAdmin && <Button
                        className="mr-4 mb-2 mb-sm-0"
                        style={{
                          whiteSpace: "nowrap",
                          background: "var(--main-bg-color-container-header)",
                          width: "auto",
                          height: "40px"
                        }}
                        color="primary"
                        onClick={this.requestGlobalAccess}
                      >
                        Request Global Access
                      </Button>
                      }
                      <Button
                        style={{
                          whiteSpace: "nowrap",
                          background: "var(--main-bg-color-container-header)",
                          height: "40px"
                        }}
                        color="primary"
                        onClick={() => history.push(redirectPathForAddList)}
                      >
                        + Add Products List
                      </Button>
                    </div>
                  </div>

                  {this.state.categories.length > 0 ?
                    <table className='table table-bordered'>
                      <thead>
                        <tr>
                          <th>Category List</th>
                          <th>Total Items</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.categories.map((category) => (
                          <tr key={"category-" + category.id}>
                            <td>{category.categoryName}</td>
                            <td>{category.itemCount}</td>
                            <td>

                              <i onClick={() => history.push(redirectPathForProduct + category.id)} class='fa fa-eye icons-remove mr-2' style={{ fontSize: "20px", color: "blue" }} role='button'></i>

                              <i onClick={() => history.push(redirectPathForEditList + category.id)} class='fa fa-edit icons-remove mr-2' style={{ fontSize: "20px", color: "blue" }} role='button'></i>

                              <i onClick={() => {
                                this.setState({ showDeleteWarning: true, currentCategory: category })
                              }} class='fa fa-trash icons-remove' style={{ fontSize: "20px", color: "red" }} role='button' id='delete-temp'></i>


                            </td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                    :
                    <p className="ml-2">No data available</p>
                  }
                </CardBody>
              </Card>
            </div>
          </>
        }
      </>
    );
  }
}

export default withRouter(CategoryList);
