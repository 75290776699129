import Axios from "axios";
import { URLS, URLS_NEW } from "./URLS";
import { makeid } from "../utils/utils";
import moment from 'moment';


export const scanParcel = data => Axios.post(URLS.SCAN_PARCEL, data);
export const addAddress = data => Axios.post(URLS.ADD_ADDRESS, data);
export const getAddress = data => Axios.post(URLS.ADDRESS_HISTORY, data);
export const packageHandlers = data => Axios.post(URLS.PACKAGE_HANDLER, data);
export const lostParcel = data => Axios.post(URLS.LOST_PARCEL, data);
export const lostParcelSuccess = data => Axios.post(URLS.LOST_PARCEL_SUCCESS, data);
export const apiRequest = data => {
  const form = new FormData();
  form.append('name', data.name);
  form.append('email', data.email);
  form.append('site_url', data.site_url);
  form.append('access_routes', data.access_routes);
  form.append('user_id', data.user_id);
  return Axios.post(URLS_NEW.APIREQ, form)
};
export const createShipment = data => {
  // console.log('apiForm', data);
  // console.log('length', data.filesData.length);
  const form = new FormData();
  form.append('deviceADcode', data.deviceADcode);
  form.append('location', data.location);
  form.append('nfcADcode', data.nfcADcode);
  form.append('nfcserialno', data.nfcserialno);
  form.append('quantity', data.quantity);
  form.append('refno', data.refno);
  form.append('trackingstatus', data.trackingstatus);
  form.append('type', data.type);
  form.append('userId', data.userId);
  form.append('phoneNumber', data.phoneNumber);
  form.append('comment', data.comment);
  form.append('origin', data.origin);
  form.append('destination', data.destination);
  form.append('status', data.status);
  form.append('carrierName', data.carrierName);
  form.append('deliveryAddress', data.deliveryAddress);
  form.append('packageType', data.packageType);
  form.append('modeOfTransport', data.modeOfTransport);
  form.append('weight', (data.weight) ? data.weight : '0');
  form.append('height', (data.height) ? data.height : '0');
  form.append('width', (data.width) ? data.width : '0');
  form.append('length', (data.length) ? data.length : '0');
  form.append('receiverName', data.receiverName);
  form.append(`receiverBusinessName`, data.receiverBusinessName);
  form.append(`receiverEmail`, data.receiverEmail);
  form.append(`pickup`, data.pickup);
  form.append(`customerRefNo`, data.customerRefNo);
  form.append(`packageCurrency`, data.packageCurrency);
  form.append(`packageValue`, (data.packageValue) ? data.packageValue : '0');
  form.append(`receiverPhoneNumber`, data.receiverPhoneNumber);
  form.append(`shipmentType`, data.shipmentType);
  form.append(`originCountry`, data.origin_country);
  form.append(`destinationCountry`, data.destination_country);
  form.append(`shipmentDescription`, data.shipmentDescription);
  form.append(`emailAddress1`, data.emailAddress1);
  form.append(`phoneNumber1`, data.phoneNumber1);
  form.append(`shipperName1`, data.shipperName1);
  form.append(`shipperEmail1`, data.shipperEmail1);
  form.append(`shipperPhone1`, data.shipperPhone1);
  form.append(`shipperAddress1`, data.shipperAddress1);
  form.append(`enableAdCodes`, data.adCodes);

  // append multiple files
  if ('filesData' in data) {
    for (let i = 0; i < data.filesData.length; i++) {
      form.append('files[]', JSON.stringify([data.filesData[i]]));
      form.append('filesData[]', data.filesData[i].filesInfo);
    }
  }
  return Axios.post(URLS_NEW.CREATE_SHIPMENT, form)
};

export const editShipment = data => {
  const form = new FormData();
  if (data.mode === 'editShipment') {
    form.append('id', data.trackingId);
    form.append('deviceADcode', data.deviceADcode);
    form.append('location', data.location);
    form.append('nfcADcode', data.nfcADcode);
    form.append('nfcserialno', data.nfcserialno);
    form.append('quantity', data.quantity);
    form.append('refno', data.refno);
    form.append('trackingstatus', data.trackingstatus);
    form.append('type', data.type);
    form.append('userId', data.userId);
    form.append('phoneNumber', data.phoneNumber);
    form.append('comment', data.comment);
    form.append('origin', data.origin);
    form.append('destination', data.destination);
    form.append('status', data.status);
    form.append('carrierName', data.carrierName);
    form.append('deliveryAddress', data.deliveryAddress);
    form.append('internalComment', data.internalComment)
    form.append('isPublished', data.isPublish)
    form.append('packageType', data.packageType);
    form.append('weight', data.weight);
    form.append('height', data.height);
    form.append('width', data.width);
    form.append('length', data.length);
    form.append('receiverName', data.receiverName);
    form.append(`receiverBusinessName`, data.receiverBusinessName);
    form.append(`receiverEmail`, data.receiverEmail);
    form.append(`pickup`, data.pickup);
    form.append(`customerRefNo`, data.customerRefNo);
    form.append(`packageCurrency`, data.packageCurrency);
    form.append(`packageValue`, (data.packageValue) ? data.packageValue : '0');
    form.append(`receiverPhone`, data.receiverPhoneNumber);
    form.append(`mode`, data.mode);
    form.append(`old_invoice`, data.old_invoice);
    form.append(`originCountry`, data.origin_country);
    form.append(`destinationCountry`, data.destination_country);
    form.append(`shipmentDescription`, data.shipmentDescription);
    form.append(`modeOfTransport`, data.modeOfTransport);
    form.append(`emailAddress1`, data.emailAddress1);
    form.append(`phoneNumber1`, data.phoneNumber1);
    form.append(`shipperName1`, data.shipperName1);
    form.append(`shipperEmail1`, data.shipperEmail1);
    form.append(`shipperPhone1`, data.shipperPhone1);
    form.append(`shipperAddress1`, data.shipperAddress1);
    form.append(`enableAdCodes`, data.adCodes);
    form.append(`oldFiles`, JSON.stringify(data.oldFiles));
    // append multiple files
    if ('filesData' in data) {
      for (let i = 0; i < data.filesData.length; i++) {
        form.append('files[]', JSON.stringify([data.filesData[i]]));
        form.append('filesData[]', data.filesData[i].filesInfo);
      }
    }
    // if (data.invoice) {
    //   form.append('invoice', data.invoice);
    // }
    // var imagedata = document.querySelector('input[type="file"]').files[0];
    // form.append("invoice", imagedata);
  } else {
    form.append('refno', data.refno);
    form.append(`mode`, data.mode);
  }

  return Axios.post(URLS_NEW.EDIT_SHIPMENT, form)
};

export const scanMultipleParcels = data => Axios.post(URLS.SCAN_MULTIPLE_PARCEL, data);

export const scanMultipleParcelsUpdated = data => Axios.post(URLS_NEW.SCAN_MULTIPLE_PARCEL, data);

export const validateRefNums = data => Axios.post(URLS_NEW.VALIDATE_REF_NUM, data);
export const validateManifestNums = data => Axios.post(URLS_NEW.VALIDATE_MANIFEST_NUM, data)

export const shipmentMultiple = data => {
  const form = new FormData();
  let i = 0;
  for (let d of data) {
    form.append(`shipments[${i}][userId]`, d.userId);
    form.append(`shipments[${i}][deviceADcode]`, d.deviceADcode);
    form.append(`shipments[${i}][refno]`, d.refno);
    form.append(`shipments[${i}][quantity]`, d.quantity);
    form.append(`shipments[${i}][comment]`, d.comment);
    form.append(`shipments[${i}][nfcserialno]`, d.nfcserialno);
    form.append(`shipments[${i}][nfcADcode]`, d.nfcADcode);
    form.append(`shipments[${i}][trackingstatus]`, d.trackingstatus);
    form.append(`shipments[${i}][location]`, d.location);
    form.append(`shipments[${i}][origin]`, d.origin);
    form.append(`shipments[${i}][destination]`, d.destination);
    form.append(`shipments[${i}][phoneNumber]`, d.phoneNumber);
    form.append(`shipments[${i}][type]`, d.type);
    form.append(`shipments[${i}][status]`, d.status);
    form.append(`shipments[${i}][carrierName]`, d.carrierName);
    form.append(`shipments[${i}][deliveryAddress]`, d.deliveryAddress);
    form.append(`shipments[${i}][weight]`, d.weight);
    form.append(`shipments[${i}][packageType]`, d.packageType);
    form.append(`shipments[${i}][height]`, d.height);
    form.append(`shipments[${i}][width]`, d.width);
    form.append(`shipments[${i}][length]`, d.length);
    form.append(`shipments[${i}][receiverName]`, d.receiverName);
    form.append(`shipments[${i}][receiverBusinessName]`, d.receiverBusinessName);
    form.append(`shipments[${i}][receiverEmail]`, d.receiverEmail);
    i++;
  }
  if (data.invoice) {
    form.append(makeid(10) + 'invoice', data.invoice);
  }
  return Axios.post(URLS.SHIPMENT_MULTIPLE, form)
};
export const shippingHistory = data => Axios.post(URLS.SHIPPING_HISTORY, data)
export const uploadCSV = data => Axios.post(URLS.SHIPMENT_MULTIPLE_UPDATED, data);
export const assignMasterNumberApi = data => Axios.post(URLS_NEW.ASSIGN_MASTER_NUMBER, data);
export const updateStatusAirway = data => Axios.post(URLS_NEW.UPDATE_STATUS_AIRWAY, data);
export const lookUpAirway = data => Axios.post(URLS_NEW.LOOKUP_AIRWAY, data);
export const downloadManifest = data => Axios.post(URLS_NEW.GET_MANIFEST_DETAILS, data);
export const requestAccess = data => Axios.post(URLS.REQUEST_ACCESS, data);
export const getAllRiders = data => Axios.post(URLS_NEW.GET_ALL_RIDERS, data);
export const updateStatus = data => Axios.post(URLS_NEW.UPDATE_SCREEN_ACCESS, data);
export const getMultipleShipments = data => Axios.post(URLS_NEW.GET_MULTIPLE_SHIPMENTS, data);
export const getConsigneeDetails = data => Axios.post(URLS_NEW.GET_CONSIGNEE_DETAILS, data);
export const getLastShipments = data => Axios.post(URLS_NEW.GET_LAST_SHIPMENT, data);
export const searchRequests = data => Axios.post(URLS_NEW.SEARCH_REQUESTS, data);
export const checkScreenAccess = data => Axios.post(URLS_NEW.CHECK_SCREEN_ACCESS, data);
export const getAllScreenAccessRequests = data => Axios.post(URLS_NEW.GET_SCREEN_ACCESS_REQUESTS, data);
export const trackShipment = data => Axios.post(URLS.TRACK_SHIPMENT, data);
export const incomingShipments = data => Axios.post(URLS.INCOMING_SHIPMENT, data);
export const courierDashBoard = data => Axios.post(URLS.COURIER_DASHBOARD, data);
export const saveflightDetails = data => Axios.post(URLS_NEW.SAVE_FLIGHT_DETAILS, data);
export const saveExternalRefNums = data => Axios.post(URLS_NEW.SAVE_EXTERNAL_REFS, data);
export const saveExternalRefNumsUnassign = data => Axios.post(URLS_NEW.SAVE_EXTERNAL_REFS_UNSASSIGN, data);
export const getExternaTrackingList = data => Axios.post(URLS_NEW.EXTERNAL_TRACKING_LIST, data);
export const getExternalTrackingDetail = data => Axios.post(URLS_NEW.GET_EXTERNAL_TRACKING_NUMBER_DETAIL, data);
export const updateExternalRefNums = data => Axios.post(URLS_NEW.UPDATE_EXTERNAL_REFS, data);
export const deleteExternalRefNums = data => Axios.post(URLS_NEW.DELETE_EXTERNAL_REFS, data);
export const searchExternalRefNums = data => Axios.post(URLS_NEW.SEARCH_EXTERNAL_REFS, data);
export const outgoingShipments = data => Axios.post(URLS.OUTGOING_SHIPMENT, data);
export const validateExternalRefNum = data => Axios.post(URLS_NEW.VALIDATE_EXT_NUM, data);
export const externalTracking = data => Axios.post(URLS_NEW.EXTERNAL_TRACKING, data);
export const shipmentFilterResults = data => Axios.post(URLS_NEW.GET_SHIPMENTS_FILTER_WISE, data);

export const search = data => Axios.post(URLS.SEARCH, data);

export const chatsRequest = data => Axios.post(URLS.CHAT_REQUEST, data);
export const chatsConfirmRequest = data => Axios.post(URLS.CONFIRM_REQUEST, data);
export const chatsList = data => Axios.post(URLS.CHAT_LIST, data);
export const sendChats = data => Axios.post(URLS.SEND_CHAT, data);

export const readChats = data => Axios.post(URLS.READ_CHAT, data);

export const chatsMessagesList = data => Axios.post(URLS.CHAT_MESSAGES_LIST, data);
export const deleteChats = data => Axios.post(URLS.DELETE_CHAT, data);
export const deleteMessage = data => Axios.post(URLS.DELETE_MESSAGE, data);


export const updateChats = data => Axios.post(URLS.UPDATE_CHAT, data);
export const call = data => Axios.post(URLS.CALL, data);

export const contacts = data => Axios.post(URLS.CONTACTS, data);
export const help = data => Axios.post(URLS.HELP, data);
export const getShipment = data => Axios.post(URLS.GET_SHIPMENT, data);
export const getPriceList = data => Axios.get(URLS_NEW.GET_PRICE_LIST, data);
export const updatePriceList = data => {
  const form = new FormData();
  form.append('bike_per_km', data.bike_per_km);
  form.append('bike_per_min', data.bike_per_min);
  form.append('car_per_km', data.car_per_km);
  form.append('car_per_min', data.car_per_min);
  return Axios.post(URLS_NEW.UPDATE_PRICE_LIST, form)
};

export const grantApiAccess = data => {
  const form = new FormData();
  form.append('access_status', data.access_status);
  form.append('access_token', data.access_token);
  form.append('email', data.email);
  form.append('name', data.name);
  form.append('validity', moment(data.validity).format('YYYY-M-D'));
  form.append('id', data.id);
  return Axios.post(URLS_NEW.GRANT_ACCESS, form)
};
export const getApiClientList = data => Axios.get(URLS_NEW.GET_API_CLIENT_LIST, data);
export const getApiClientDetail = data => {
  const form = new FormData();
  form.append('user_id', data);
  return Axios.post(URLS_NEW.GET_API_CLIENT_DETAIL, form)
};
export const userProfile = data => {
  const form = new FormData();
  form.append('userId', data.userId);
  form.append('accountType', data.accountType);
  return Axios.post(URLS.PROFILE, form);
}

export const addCall = data => Axios.post(URLS.ADD_CALL, data);
export const updateCall = data => Axios.post(URLS.UPDATE_CALL, data);
export const callHistory = data => Axios.post(URLS.CALL_HISTORY, data);

export const srRequest = data => Axios.post(URLS.SR_REQUEST, data);
export const saveUserActivity = data => Axios.post(URLS_NEW.SAVE_USER_ACTIVITY, data);
export const getUserActivities = data => Axios.post(URLS_NEW.GET_USER_ACTIVITIES, data);
export const validateUserAdCodes = data => Axios.post(URLS_NEW.VALIDATE_USER_ADCODES, data);
export const getShippingDocs = data => Axios.post(URLS_NEW.SHIPPING_DOCS, data);
export const updateUserProfile = data => {

  const form = new FormData();

  form.append('userId', data.userId);
  form.append('firstname', data.firstname);
  form.append('lastname', data.lastname);
  form.append('unitno', data.unitno);
  form.append('houseno', data.houseno);
  form.append('streetname', data.streetname);
  form.append('suburb', data.suburb);
  form.append('state', data.state);
  form.append('postcode', data.postcode);
  form.append('country', data.country);
  form.append('phone', data.phone);
  form.append('accounttype', data.accounttype);
  form.append('businessname', data.businessname);
  form.append('businesstype', data.businesstype);
  form.append('addressdescription', data.addressdescription);
  var imagedata = document.querySelector('input[type="file"]').files[0];
  form.append("profile", imagedata);

  return Axios.post(URLS_NEW.UPDATE_PROFILE, form)
};
export const updateNoticationStatusForExternal = data => Axios.post(URLS_NEW.UPDATE_EXTERNAL_NOTIFICATION_STATUS, data);
export const searchUserByAdCode = data => Axios.post(URLS_NEW.SEARCH_USER_BY_ADCODE, data);
export const addCredits = data => Axios.post(URLS_NEW.ADD_CREDITS, data);
export const sendBulkMessages = data => Axios.post(URLS_NEW.SEND_BULK_MESSAGES, data);
export const customTracking = data => Axios.post(URLS_NEW.CUSTOM_TRACKING, data);
export const searchShipmentInvoice = data => Axios.post(URLS_NEW.SEARCH_SHIPMENT_INVOICE, data);
export const updateShipmentInvoice = data => Axios.post(URLS_NEW.UPDATE_SHIPMENT_INVOICE, data);
export const sendInvoice = data => Axios.post(URLS_NEW.SEND_INVOICE, data);
export const saveUserPreferences = data => Axios.post(URLS_NEW.SAVE_USER_PREFRENCES, data);
export const userPreferences = data => Axios.post(URLS_NEW.GET_USER_PREFRENCES, data);
export const createPayment = data => Axios.post(URLS_NEW.CREATE_PAYMENT, data);
export const updateCreditUnits = data => Axios.post(URLS_NEW.UPDATE_CREDIT_UNITS, data);
export const getCreditUnits = data => Axios.post(URLS_NEW.GET_CREDIT_UNITS, data);
export const getPurchaseHistory = data => Axios.post(URLS_NEW.PAYMENT_HISTORY, data);
export const apiReports = data => Axios.post(URLS_NEW.API_REPORTS, data);
export const getInvoiceReports = data => Axios.post(URLS_NEW.INVOICE_REPORTS, data);
export const getTemplateLsit = data => Axios.post(URLS_NEW.ADHOC_TEMPLATE_LIST, data);
export const createTemplate = data => Axios.post(URLS_NEW.CREATE_ADHOC_TEMPLATE, data);
export const updateTemplate = data => Axios.post(URLS_NEW.UPDATE_ADHOC_TEMPLATE, data);
export const deleteTemplate = data => Axios.post(URLS_NEW.DELETE_ADHOC_TEMPLATE, data);
export const checkAdCode = data => Axios.post(URLS_NEW.CHECK_AD_CODE, data);
export const checkTrackNumber = data => Axios.post(URLS_NEW.CHECK_TRACK_NUMBER, data);
export const generateAdhocInvoice = data => Axios.post(URLS_NEW.GENERATE_ADHOC_INVOICE, data);
export const checkUniqueNumber = data => Axios.post(URLS_NEW.CHECK_UNIQUE_NUMBER, data);
export const trackingMoreData = data => Axios.post(URLS_NEW.TRACKING_MORE_DATA, data);
export const saveTrackingNimber = data => Axios.post(URLS_NEW.SAVE_TRACKING_NUMBER, data);
export const getTrackingNimber = data => Axios.post(URLS_NEW.GET_TRACKING_NUMBER, data);
export const deleteTrackingNimber = data => Axios.post(URLS_NEW.DELETE_TRACKING_NUMBER, data);
export const countAccessPendingRequest = data => Axios.post(URLS_NEW.COUNT_ACCESS_PENDING_REQUEST);
export const getIpAddress = data => Axios.post(URLS_NEW.GET_IP_ADDRESSES, data);
export const saveIpAddress = data => Axios.post(URLS_NEW.SAVE_IP_ADDRESSES, data);
export const deleteShipment = data => Axios.post(URLS_NEW.DELETE_SHIPMENT, data);
export const getDeleteShipment = data => Axios.post(URLS_NEW.GET_DELETE_SHIPMENT, data);
export const renistateShipment = data => Axios.post(URLS_NEW.REINSTATE_SHIPMENT, data);
export const sendEmailOtp = data => Axios.post(URLS_NEW.SEND_EMAIL_OTP, data);
export const verifyEmailOtp = data => Axios.post(URLS_NEW.VERIFY_EMAIL_OTP, data);
export const getAllUSers = data => Axios.post(URLS_NEW.GET_ALL_USERS, data);
export const requestMobileNoVerifyRequest = data => Axios.post(URLS_NEW.ADMIN_USER_NO_VERIFY_REQUEST, data);
export const lockUserAccount = data => Axios.post(URLS_NEW.LOCK_USER_ACCOUNT, data);
export const deleteUserAccount = data => Axios.post(URLS_NEW.DELETE_USER_ACCOUNT, data);


export const searchShipmentBill = data => Axios.post(URLS_NEW.SEARCH_SHIPMENT_BILL, data);
export const createShipmentBill = data => Axios.post(URLS_NEW.CREATE_SHIPMENT_BILL, data);




// Third party api integration

export const getCountryData = iso => Axios.get(`https://restcountries.com/v3.1/alpha/${iso}`)

export const requestDemo = data => Axios.post(URLS_NEW.REQUEST_DEMO, data)

export const getBannerSlides = () => Axios.get(URLS_NEW.GET_BANNER_SLIDES)

export const updateBannerSlides = data => Axios.post(URLS_NEW.UPDATE_BANNER_SLIDES, data)

// cipl api
export const ciplHeaders = data => Axios.post(URLS_NEW.CIPL_HEADERS, data)

export const ciplProductDb = data => Axios.post(URLS_NEW.CIPL_PRODUCT_DB, data)

export const ciplBill = data => Axios.post(URLS_NEW.CIPL_BILL, data)

export const ciplSendEmail = data => Axios.post(URLS_NEW.CIPL_EMAIL, data)

export const ciplAssignRef = data => Axios.post(URLS_NEW.CIPL_ASSIGN_REF, data)

export const ciplDownload = data => Axios.post(URLS_NEW.CIPL_DOWNLOAD, data)
