import React, { Component } from 'react'
import Select from 'react-select';
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import { Button, Col, FormGroup, Label, Row, Table } from 'reactstrap';
import CustomInputDropdown from './CustomInputDropdown';
import SunEditorComponent from './Editor';
import { currencyList } from '../../../CreateShipment/CurrencyList';
import { toast } from 'react-toastify';

const discountTypeDrpdwn = [{ label: "Absolute value", value: "Absolute value", }, { label: "Percentage", value: "Percentage", }];
const discountTypes = { absolute: "Absolute value", percentage: "Percentage" };
export class CreateCiplForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    calculateFinalPrice = (price, discount, discountTypeParam) => {
        let discountType = discountTypeParam;
        if (typeof discountTypeParam === "object" && discountTypeParam !== null) {
            discountType = discountTypeParam.value;
        }

        if (!price || isNaN(price)) {
            return 0; // Default to 0 if price is invalid
        }

        if (discount === "N/A" || discount === null || discount === undefined || discount === 0 || isNaN(discount)) {
            return price; // No discount applied
        }

        if (discountType === discountTypes.absolute) {
            // Handle absolute discount
            return Math.max(price - discount, 0); // Ensure price doesn't go below zero
        } else if (discountType === discountTypes.percentage) {
            // Handle percentage discount
            if (discount < 0 || discount > 100) {
                return price; // Ignore invalid percentage discounts
            }
            return price - (price * discount) / 100;
        } else {
            // Default case for unknown discount type
            return price;
        }
    };

    render() {
        const { handleSubmit, handleBlur, validateForm, errors, touched, setFieldValue, values, validateField } = this.props.formProps;
        const { isViewOnly, isCiplSaved, currentCipl, ciplType } = this.props.states;
        const { calculateTotalWeight } = this.props.functions;
        const ciplTypes = { packingList: "Packing List", invoice: "Invoice" };
        return (
            <FormikForm onSubmit={handleSubmit}>
                <fieldset disabled={isViewOnly}>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="date">Date</Label>
                                <Field
                                    type="date"
                                    id="date"
                                    name="date"
                                    className={`form-control ${touched.date && errors.date ? "is-invalid" : ""}`}
                                    placeholder="Select a date"
                                />
                                <ErrorMessage name="date" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="mot">MOT</Label>
                                <Field
                                    onChange={(e) => {
                                        if (e.target.value !== "Sea") {
                                            setFieldValue("sealNumber", "N/A");
                                            setFieldValue("containerNumber", "N/A");
                                        }
                                        else {
                                            setFieldValue("sealNumber", values.sealNumber !== "N/A" ? values.sealNumber : "");
                                            setFieldValue("containerNumber", values.containerNumber !== "N/A" ? values.containerNumber : "");
                                        }
                                        setFieldValue("mot", e.target.value)
                                    }}
                                    as="select"
                                    id="mot"
                                    name="mot"
                                    className={`form-control ${touched.mot && errors.mot ? "is-invalid" : ""}`}
                                >
                                    <option>Select MOT</option>
                                    <option>Sea</option>
                                    <option>Air</option>
                                    <option>Rail</option>
                                    <option>Road</option>
                                </Field>
                                <ErrorMessage name="mot" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="billNumber">Bill Number</Label>
                                <Field
                                    type="text"
                                    id="billNumber"
                                    name="billNumber"
                                    className={`form-control ${touched.billNumber && errors.billNumber ? "is-invalid" : ""}`}
                                    placeholder="Enter bill number"
                                />
                                <ErrorMessage name="billNumber" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="sealNumber">Seal Number</Label>
                                <Field
                                    type="text"
                                    id="sealNumber"
                                    name="sealNumber"
                                    className={`form-control ${touched.sealNumber && errors.sealNumber ? "is-invalid" : ""}`}
                                    placeholder="Enter seal number"
                                />
                                <ErrorMessage name="sealNumber" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="containerNumber">Container Number</Label>
                                <Field
                                    type="text"
                                    id="containerNumber"
                                    name="containerNumber"
                                    className={`form-control ${touched.containerNumber && errors.containerNumber ? "is-invalid" : ""}`}
                                    placeholder="Enter container number"
                                />
                                <ErrorMessage name="containerNumber" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="customerNumber">Customer Number</Label>
                                <Field
                                    type="text"
                                    id="customerNumber"
                                    name="customerNumber"
                                    className={`form-control ${touched.customerNumber && errors.customerNumber ? "is-invalid" : ""}`}
                                    placeholder="Enter customer number"
                                />
                                <ErrorMessage name="customerNumber" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="consignee">Consignee Name</Label>
                                <Field
                                    type="text"
                                    id="consignee"
                                    name="consignee"
                                    className={`form-control ${touched.consignee && errors.consignee ? "is-invalid" : ""}`}
                                    placeholder="Enter consignee name"
                                />
                                <ErrorMessage name="consignee" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="consigneeAddress">Consignee Address</Label>
                                <Field
                                    type="text"
                                    id="consigneeAddress"
                                    name="consigneeAddress"
                                    className={`form-control ${touched.consigneeAddress && errors.consigneeAddress ? "is-invalid" : ""}`}
                                    placeholder="Enter consignee address"
                                />
                                <ErrorMessage name="consigneeAddress" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="shipTo">Ship To Name</Label>
                                <Field
                                    type="text"
                                    id="shipTo"
                                    name="shipTo"
                                    className={`form-control ${touched.shipTo && errors.shipTo ? "is-invalid" : ""}`}
                                    placeholder="Enter ship-to name"
                                />
                                <ErrorMessage name="shipTo" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="shipToAddress">Ship To Address</Label>
                                <Field
                                    type="text"
                                    id="shipToAddress"
                                    name="shipToAddress"
                                    className={`form-control ${touched.shipToAddress && errors.shipToAddress ? "is-invalid" : ""}`}
                                    placeholder="Enter ship-to address"
                                />
                                <ErrorMessage name="shipToAddress" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="hblRef">HBL/REF</Label>
                                <Field
                                    maxLength="11"
                                    type="text"
                                    id="hblRef"
                                    name="hblRef"
                                    className={`form-control ${touched.hblRef && errors.hblRef ? "is-invalid" : ""}`}
                                    placeholder="Enter HBL or reference number"
                                />
                                <ErrorMessage name="hblRef" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                        {ciplType === ciplTypes.packingList ? <Col md={3}>
                            <FormGroup>
                                <Label for="packingListNumber">Packing List Number</Label>
                                <Field
                                    disabled
                                    type="text"
                                    id="packingListNumber"
                                    name="packingListNumber"
                                    className={`form-control ${touched.packingListNumber && errors.packingListNumber ? "is-invalid" : ""}`}
                                    placeholder="Enter packing list number"
                                />
                                <ErrorMessage name="packingListNumber" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                            :
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="invoiceNo">Invoice Number</Label>
                                    <Field
                                        disabled
                                        type="text"
                                        id="invoiceNo"
                                        name="invoiceNo"
                                        className={`form-control ${touched.invoiceNo && errors.invoiceNo ? "is-invalid" : ""}`}
                                        placeholder="Enter invoice number"
                                    />
                                    <ErrorMessage name="invoiceNo" component="div" className="invalid-feedback" />
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="totalWeight">Total Weight</Label>
                                <Field
                                    disabled
                                    value={calculateTotalWeight(values.products)}
                                    type="text"
                                    id="totalWeight"
                                    name="totalWeight"
                                    className={`form-control ${touched.totalWeight && errors.totalWeight ? "is-invalid" : ""}`}
                                    placeholder="Enter total weight"
                                />
                                <ErrorMessage name="totalWeight" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="incoterm">Incoterm</Label>
                                <Field
                                    as="select"
                                    id="incoterm"
                                    name="incoterm"
                                    className={`form-control ${touched.incoterm && errors.incoterm ? "is-invalid" : ""}`}
                                >
                                    <option>Select Incoterm</option>
                                    <option>EXW</option>
                                    <option>FCA</option>
                                    <option>FAS</option>
                                    <option>FOB</option>
                                    <option>CFR</option>
                                    <option>CIF</option>
                                    <option>CPT</option>
                                    <option>CIP</option>
                                    <option>DAP</option>
                                    <option>DPU</option>
                                    <option>DDP</option>

                                </Field>
                                <ErrorMessage name="incoterm" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="invoiceTerms">Invoice terms</Label>
                                <Field
                                    as="select"
                                    id="invoiceTerms"
                                    name="invoiceTerms"
                                    className={`form-control ${touched.invoiceTerms && errors.invoiceTerms ? "is-invalid" : ""}`}
                                >
                                    <option>Select Invoice Terms</option>
                                    <option>N/A</option>
                                    <option>PREPAID</option>
                                    <option>CASH</option>
                                    <option>CASH IN ADVANCE</option>
                                    <option>CASH BEFORE SHIPMENT</option>
                                    <option>COD</option>
                                    <option>CASH NEXT DELIVERY</option>
                                    <option>CASH WITH ORDER</option>
                                    <option>END OF MONTH</option>
                                    <option>PAYMENT IN ADVANCE</option>
                                    <option>SHIPPER</option>
                                    <option>NET 2</option>
                                    <option>NET 3</option>
                                    <option>NET 4</option>
                                    <option>NET 5</option>
                                    <option>NET 6</option>
                                    <option>NET 7</option>
                                    <option>NET 8</option>
                                    <option>NET 9</option>
                                    <option>NET 10</option>
                                    <option>NET 30</option>
                                    <option>NET 60</option>
                                    <option>NET 90</option>

                                </Field>
                                <ErrorMessage name="invoiceTerms" component="div" className="invalid-feedback" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <div className="d-flex justify-content-between align-items-end">
                        <p style={{ color: "black" }} className="font-weight-bold mt-4">Product details</p>
                        <div className="form-check mb-2">
                            <Field
                                type="checkbox"
                                className="form-check-input"
                                id="printHsCode"
                                name="printHsCode"
                                checked={values.printHsCode === 1}
                                onChange={() => setFieldValue('printHsCode', values.printHsCode === 1 ? 0 : 1)}
                            />
                            <label className="form-check-label" htmlFor="printHsCode">
                                Print HS Code
                            </label>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "40px" }}>Item #</th>
                                    <th style={{ width: "120px" }}>Product Description</th>
                                    <th>Weight</th>
                                    <th style={{ minWidth: "150px" }}>
                                        <div>Price</div>
                                        <FormGroup style={{ height: "auto", width: "140px", marginTop: "10px", marginBottom: "0" }}>
                                            <Select
                                                styles={{
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999, // Ensure dropdown appears above other elements
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        maxHeight: 300,
                                                        overflowY: "auto", // Add scroll if options exceed height
                                                    }),
                                                }}
                                                menuPlacement="auto"
                                                menuPortalTarget={document.body}
                                                placeholder={<div className="font-weight-light">Currency</div>}
                                                name="currency"
                                                onChange={(option) => setFieldValue('currency', option.value)}
                                                onBlur={handleBlur}
                                                className="is-invalid"
                                                value={currencyList.filter(option => values.currency === option.value)}
                                                options={currencyList}
                                            />
                                        </FormGroup>
                                    </th>
                                    <th style={{ minWidth: "150px" }}>
                                        <div>Discount</div>
                                        <FormGroup style={{ height: "auto", width: "140px", marginTop: "10px", marginBottom: "0" }}>
                                            <Select
                                                styles={{
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999, // Ensure dropdown appears above other elements
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        maxHeight: 100,
                                                        overflowY: "auto", // Add scroll if options exceed height
                                                    }),
                                                }}
                                                menuPlacement="auto"
                                                menuPortalTarget={document.body}
                                                placeholder={<div className="font-weight-light">Discount type</div>}
                                                name="discountType"
                                                onBlur={handleBlur}
                                                defaultValue={values.discountType}
                                                onChange={(option) => {
                                                    setFieldValue(`discountType`, option.value);
                                                    const finalPrice = this.calculateFinalPrice(values.productToAdd.price, values.productToAdd.discount, option.value);
                                                    setFieldValue(`productToAdd.finalPrice`, finalPrice);
                                                    values.products.forEach((_, index) => {
                                                        const finalPrice = this.calculateFinalPrice(values.products[index].price, values.products[index].discount, option.value);
                                                        setFieldValue(`products[${index}].finalPrice`, finalPrice);
                                                    })
                                                }}
                                                className="is-invalid"
                                                options={discountTypeDrpdwn}
                                            />
                                        </FormGroup>
                                    </th>
                                    <th style={{ minWidth: "100px" }}>Final price</th>
                                    <th>HS Code/Tariff No</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {values.products.map((product, index) => (
                                    <tr key={`product-${index}`}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <CustomInputDropdown
                                                name={`products[${index}].name`}
                                                codeName={`products[${index}].code`}
                                                codeDisable={`products[${index}].disableHsCode`}
                                            />
                                            <ErrorMessage
                                                name={`products[${index}].name`}
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </td>
                                        <td>
                                            <Field
                                                maxLength="15"
                                                type="text"
                                                name={`products[${index}].weight`}
                                                className={`form-control ${touched.products &&
                                                    touched.products[index]?.weight &&
                                                    errors.products &&
                                                    errors.products[index]?.weight
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                placeholder="Weight"
                                            />
                                            <ErrorMessage
                                                name={`products[${index}].weight`}
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </td>
                                        <td>
                                            <Field onChange={(e) => {
                                                if (!values.currency) {
                                                    toast.error('Please Select Currency first', {
                                                        position: toast.POSITION.TOP_RIGHT
                                                    })
                                                    return;
                                                }
                                                else {
                                                    setFieldValue(`products[${index}].price`, e.target.value);
                                                    const finalPrice = this.calculateFinalPrice(e.target.value, values.products[index]?.discount, values.discountType);
                                                    setFieldValue(`products[${index}].finalPrice`, finalPrice);
                                                }
                                            }}
                                                type="text"
                                                maxLength="15"
                                                name={`products[${index}].price`}
                                                className={`form-control ${touched.products &&
                                                    touched.products[index]?.price &&
                                                    errors.products &&
                                                    errors.products[index]?.price
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                placeholder="Price"
                                            />
                                            <ErrorMessage
                                                name={`products[${index}].price`}
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </td>
                                        <td>
                                            <Field
                                                maxLength="15"
                                                type="text"
                                                name={`products[${index}].discount`}
                                                className={`form-control ${touched.products &&
                                                    touched.products[index]?.discount &&
                                                    errors.products &&
                                                    errors.products[index]?.discount
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                placeholder="Discount"
                                                onChange={(e) => {
                                                    if (!values.discountType) {
                                                        toast.error('Please select discount type first', {
                                                            position: toast.POSITION.TOP_RIGHT
                                                        })
                                                        return;
                                                    }
                                                    setFieldValue(`products[${index}].discount`, e.target.value);
                                                    const finalPrice = this.calculateFinalPrice(values.products[index]?.price, e.target.value, values.discountType);
                                                    setFieldValue(`products[${index}].finalPrice`, finalPrice);
                                                }
                                                }
                                            />
                                            <ErrorMessage
                                                name={`products[${index}].discount`}
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </td>
                                        <td>
                                            <Field
                                                disabled
                                                type="text"
                                                name={`products[${index}].finalPrice`}
                                                className={`form-control ${touched.products &&
                                                    touched.products[index]?.finalPrice &&
                                                    errors.products &&
                                                    errors.products[index]?.finalPrice
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                placeholder="Final price"
                                            />
                                            <ErrorMessage
                                                name={`products[${index}].finalPrice`}
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </td>
                                        <td>
                                            <Field
                                                maxLength="15"
                                                disabled={values.products[index].disableHsCode}
                                                type="text"
                                                name={`products[${index}].code`}
                                                className={`form-control ${touched.products &&
                                                    touched.products[index]?.code &&
                                                    errors.products &&
                                                    errors.products[index]?.code
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                placeholder="HS code"
                                            />
                                            <ErrorMessage
                                                name={`products[${index}].code`}
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </td>
                                        <td>
                                            <Button
                                                className="d-flex align-items-center"
                                                style={{ width: "100px" }}
                                                color="danger"
                                                onClick={() => {
                                                    const updatedProducts = values.products.filter((_, i) => i !== index);
                                                    setFieldValue("products", updatedProducts);
                                                }}
                                            >
                                                <i class="fa fa-trash icons-remove mr-2"></i> Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))}

                                {/* Placeholder Row for Adding New Product */}
                                {!isViewOnly && <tr key="new-product">
                                    <td>{values.products.length + 1}</td>
                                    <td>
                                        <div>
                                            <CustomInputDropdown
                                                name="productToAdd.name"
                                                codeName="productToAdd.code"
                                                codeDisable="productToAdd.disableHsCode"
                                            />
                                        </div>

                                        <ErrorMessage
                                            name="productToAdd.name"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            maxLength="15"
                                            type="text"
                                            name="productToAdd.weight"
                                            className={`form-control ${touched.productToAdd?.weight && errors.productToAdd?.weight
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            placeholder="Weight"
                                        />
                                        <ErrorMessage
                                            name="productToAdd.weight"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            maxLength="15"
                                            type="text"
                                            name="productToAdd.price"
                                            className={`form-control ${touched.productToAdd?.price && errors.productToAdd?.price
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            onChange={(e) => {
                                                if (!values.currency) {
                                                    toast.error('Please Select Currency first', {
                                                        position: toast.POSITION.TOP_RIGHT
                                                    })
                                                }
                                                else {
                                                    setFieldValue("productToAdd.price", e.target.value);
                                                    const finalPrice = this.calculateFinalPrice(e.target.value, values.productToAdd.discount, values.discountType);
                                                    setFieldValue(`productToAdd.finalPrice`, finalPrice);
                                                }
                                            }}
                                            placeholder="Price"
                                        />
                                        <ErrorMessage
                                            name="productToAdd.price"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            maxLength="15"
                                            type="text"
                                            name={`productToAdd.discount`}
                                            className={`form-control ${touched.productToAdd &&
                                                touched.productToAdd.discount &&
                                                errors.productToAdd &&
                                                errors.productToAdd.discount
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            placeholder="Discount"
                                            onChange={(e) => {
                                                if (!values.discountType) {
                                                    toast.error('Please select discount type first', {
                                                        position: toast.POSITION.TOP_RIGHT
                                                    })
                                                    return;
                                                }
                                                setFieldValue(`productToAdd.discount`, e.target.value);
                                                const finalPrice = this.calculateFinalPrice(values.productToAdd.price, e.target.value, values.discountType);
                                                setFieldValue(`productToAdd.finalPrice`, finalPrice);
                                            }
                                            }
                                        />
                                        <ErrorMessage
                                            name={`productToAdd.discount`}
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            disabled
                                            type="text"
                                            name={`productToAdd.finalPrice`}
                                            className={`form-control ${touched.productToAdd &&
                                                touched.productToAdd.finalPrice &&
                                                errors.productToAdd &&
                                                errors.productToAdd.finalPrice
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            placeholder="Final price"
                                        />
                                        <ErrorMessage
                                            name={`productToAdd.finalPrice`}
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            maxLength="15"
                                            disabled={values.productToAdd.disableHsCode}
                                            type="text"
                                            name="productToAdd.code"
                                            className={`form-control ${touched.productToAdd?.code && errors.productToAdd?.code
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            placeholder="HS code"
                                        />
                                        <ErrorMessage
                                            name="productToAdd.code"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            style={{ width: "100px" }}
                                            color="primary"
                                            onClick={async () => {
                                                // Access updated errors after validation
                                                const updatedErrors = await validateForm(); // This validates all fields and updates the `errors` object

                                                const productToAdd = values.productToAdd;
                                                const isNameFilled = productToAdd.name.trim() !== "";

                                                if (!isNameFilled) {
                                                    toast.error("Cannot add product with empty description. Please fill description field first.")
                                                    return;
                                                }
                                                const duplicateProduct = values.products.find(
                                                    (product) => String(product.name) === String(productToAdd.name) && String(product.code) === String(productToAdd.code))
                                                if (duplicateProduct) {
                                                    toast.error(`Duplicate product found with name ${duplicateProduct.name}`);
                                                    return;
                                                }
                                                // Check for errors in "productToAdd"
                                                if (updatedErrors.productToAdd) {
                                                    toast.error(Object.values(updatedErrors.productToAdd)[0]);
                                                    return; // Exit if validation fails
                                                }

                                                const newProduct = { ...values.productToAdd };
                                                const updatedProducts = [...values.products, newProduct];

                                                setFieldValue("products", updatedProducts);
                                                setFieldValue("totalWeight", calculateTotalWeight(updatedProducts));
                                                setFieldValue("productToAdd", {
                                                    name: "",
                                                    weight: "",
                                                    price: "",
                                                    code: "",
                                                    discount: "",
                                                    finalPrice: "",
                                                });

                                            }}
                                        >
                                            + Add
                                        </Button>
                                    </td>
                                </tr>
                                }
                            </tbody>

                        </Table>
                    </div>

                    <FormGroup className="mt-4">
                        <Label for="comments">Comments</Label>
                        <SunEditorComponent
                            disabled={isViewOnly}
                            name="comments"
                            value={values.comments}
                            onChange={setFieldValue}
                            error={errors.comments}
                            touched={touched.comments}
                            setFieldValue={setFieldValue}
                        />
                        <ErrorMessage name="comments" component="div" className="invalid-feedback" />
                    </FormGroup>
                </fieldset>

                <div className="d-flex justify-content-end mt-4">
                    {isViewOnly ? <Button style={{ width: "160px" }} onClick={(e) => {
                        e.preventDefault();
                        this.props.updateParentState({ isViewOnly: false })
                    }} color="primary" className="me-2 mr-2">
                        Edit Changes
                    </Button>
                        :
                        <Button style={{ width: "160px" }} type="submit" color="primary" className="me-2 mr-2">
                            Save Changes
                        </Button>
                    }
                    {currentCipl && isViewOnly && <Button
                        style={{ width: "160px" }}
                        onClick={() => {
                            validateForm().then((errors) => {
                                if (Object.keys(errors).length === 0) {
                                    const ciplPrintFromStorage = localStorage.getItem("cipl_print");
                                    const ciplPrintData = ciplPrintFromStorage ? JSON.parse(ciplPrintFromStorage) : {};
                                    const ciplPrintDataUpdated = { ...ciplPrintData, ...values, id: currentCipl.id, ciplType: currentCipl.ciplType };
                                    localStorage.setItem("cipl_print", JSON.stringify(ciplPrintDataUpdated));
                                    this.props.updateParentState({ print: true })
                                } else {
                                    toast.error("Please fill all fields first")
                                }
                            });
                        }} color="secondary">
                        Print
                    </Button>
                    }
                </div>
            </FormikForm>
        )
    }
}

export default CreateCiplForm