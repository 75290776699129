import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { checkScreenAccess, ciplProductDb, requestAccess } from '../../../api/Form';
import { toast } from 'react-toastify';
import { withRouter } from "react-router-dom";

class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            categoryId: null,
            categoryName: "",
            currentProduct: null,
            access: false,
            showDeleteWarning: false,
        };
    }

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('auth'));
        if (userInfo && userInfo.accounttypeUsers !== "Admin") {
            const screenAccessPayload = {
                userId: userInfo.id,
                screenType: "CIPL Product Database"
            }
            const checkProductDbAccess = () => {
                checkScreenAccess(screenAccessPayload).then((response) => {
                    this.setState({ access: true });
                    const params = new URLSearchParams(window.location.search);
                    const categoryIdFromUrl = params.get("category-id");
                    if (categoryIdFromUrl) {
                        this.setState({ categoryId: categoryIdFromUrl });
                        this.fetchProductList(categoryIdFromUrl);
                    }
                }).catch((error) => { this.setState({ access: false }); })
            }
            checkProductDbAccess();
        }
        else {
            this.setState({ access: true });
            const params = new URLSearchParams(window.location.search);
            const categoryIdFromUrl = params.get("category-id");
            if (categoryIdFromUrl) {
                this.setState({ categoryId: categoryIdFromUrl });
                this.fetchProductList(categoryIdFromUrl);
            }
        }
    }
    fetchProductList = async (categoryId) => {
        const deviceADcode = localStorage.getItem('adCodes');
        const userId = localStorage.getItem('userId');
        const getProductsPayload = {
            deviceADcode,
            userId,
            mode: 'edit-product-list',
            id: categoryId
        };
        try {
            const response = await ciplProductDb(getProductsPayload)
            if (response?.data)
                this.setState({ products: JSON.parse(response.data.data.items), categoryName: response.data.data.categoryName });
        }
        catch (error) {

        };
    };

    closeDeleteWarning = () => {
        this.setState({ showDeleteWarning: false });
    }

    deleteProduct = async () => {
        const deviceADcode = localStorage.getItem('adCodes');
        const userId = localStorage.getItem('userId');
        const params = new URLSearchParams(window.location.search);
        const categoryIdFromUrl = params.get("category-id");
        const updatedProductList = this.state.products.filter(product => product.name !== this.state.currentProduct.name);
        const editProductListPayload = {
            id: categoryIdFromUrl,
            deviceADcode,
            userId,
            mode: 'delete-category-product-list',
            categoryName: this.state.categoryName,
            items: updatedProductList,
            isGlobalSave: 0
        };

        try {
            const response = await ciplProductDb(editProductListPayload)
            if (response?.data) {
                this.closeDeleteWarning();
                this.setState({ products: updatedProductList });
                toast.success(response.data.message);
                if (updatedProductList.length === 0) {
                    this.props.history.push('/admin/product-database')
                }
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || "An unexpected error occurred.");
        }
        finally {
            this.setState({ showDeleteWarning: false })
        }
    }

    sendRequestAccess = async () => {
        const info = localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        requestAccess({
            'username': sendInfo.username,
            'firstName': sendInfo.firstName,
            'lastName': sendInfo.lastName,
            'accounttypeUsers': sendInfo.accounttypeUsers,
            'businessname': sendInfo.businessname,
            'ADCode': sendInfo.ADCode,
            'address': sendInfo.address,
            'email': sendInfo.email,
            'phoneNumber': sendInfo.phonenumber,
            'userId': sendInfo.id,
            'screenType': 'CIPL Product Database',
        }).then(Response => {
            toast.success(Response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(err => {
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    };

    render() {

        const { location, history } = this.props;
        const basePath = location.pathname.split('/').slice(0, -1).join('/');
        const redirectPathForEditList = basePath + '/create-product-list?category-id=' + this.state.categoryId;

        return (
            <>
                {!this.state.access ? <div className="justify-content-center pt-2 main-contain-title">
                    <>
                        <p>This feature is restricted.</p>
                        <Button
                            onClick={() => this.sendRequestAccess()}
                        >Request Access for free</Button>
                    </>
                </div> :
                    <>
                        <Modal isOpen={this.state.showDeleteWarning} toggle={this.closeDeleteWarning}>
                            <ModalHeader>Are you sure?</ModalHeader>
                            <ModalBody>
                                <div className="row d-flex justify-content-center py-2 px-4">
                                    {this.state.products.length > 1 ? "This action can not be reversed. This product will be permamemtly deleted. Do you want to delete?"
                                        : "Deleting this product will also delete its product list (category). Do you want to delete?"
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" onClick={this.deleteProduct}>Delete</Button>
                                <Button color="secondary" onClick={this.closeDeleteWarning}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                        <div className='w-100 px-0 py-2'>
                            <Card>
                                <CardHeader onClick={() => history.push(basePath + "/product-database")} className="c-header">Product Database</CardHeader>
                                <CardBody>
                                    <div className="d-flex justify-content-between w-100 my-3">
                                        <h4 className="ml-3">{this.state.categoryName}</h4>
                                        <div className='ms-auto text-end mr-3'>
                                            <Button style={{ whiteSpace: "nowrap" }} color={"primary"} onClick={() => history.push(redirectPathForEditList)}> Edit Product List</Button>
                                        </div>
                                    </div>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Product Name</th>
                                                <th>HS Code/Tariff No</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.products.map((product) => (
                                                <tr key={"product-" + product}>
                                                    <td>{product.name}</td>
                                                    <td>{product.code}</td>
                                                    <td>
                                                        <i onClick={() => {
                                                            this.setState({ showDeleteWarning: true });
                                                            this.setState({ currentProduct: product })
                                                        }} class='fa fa-trash icons-remove' style={{ fontSize: "20px", color: "red" }} role='button' id='delete-temp'></i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </div>
                    </>
                }
            </>
        );
    }
}

export default withRouter(ProductList);
