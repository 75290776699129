import React, { useEffect } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const SunEditorComponent = ({ disabled, value, onChange, name, error, touched, setFieldValue }) => {
    useEffect(() => {
        if (value) {
            setFieldValue("comments", value);
        }
    }, [value]);

    return (
        <div className="mb-3">
            <SunEditor
                disable={disabled}
                setOptions={{
                    height: 100,
                    buttonList: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['list', 'align', 'font', 'fontSize', 'formatBlock'],
                        // ['link', 'image', 'video', 'codeView'],
                    ],
                }}
                setContents={value} // Initialize SunEditor with form value
                onChange={(content) => onChange(name, content)} // Sync SunEditor content with Formik
            />
            {error && touched && <div className="text-danger mt-2">{error}</div>}
        </div>
    );
};

export default SunEditorComponent;
