import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { checkScreenAccess, ciplHeaders, requestAccess } from '../../api/Form';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PhoneInput from 'react-intl-tel-input';
import { isValidNumber } from 'libphonenumber-js';

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    address: Yup.string().required('Address is required'),
    phoneNumber: Yup.string()
        .required('Phone number is required')
        .test('is-valid-phone', 'Invalid phone number', (value) => isValidNumber(value || '')),
    email: Yup.string().email('Invalid email address').required('Email is required'),
});

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            headers: [],
            currentHeader: null,
            showDeleteWarning: false,
            userData: null,
            access: false,
            showPhoneErr: false,
            dialCode: ''
        };
    }

    phoneNumberRef = React.createRef();

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('auth'));
        if (userInfo && userInfo.accounttypeUsers !== "Admin") {
            const screenAccessPayload = {
                userId: userInfo.id,
                screenType: "CIPL Header"
            }
            const checkHeaderAccess = () => {
                checkScreenAccess(screenAccessPayload).then((response) => {
                    this.setState({ access: true });
                    this.fetchHeaders();
                }).catch((error) => { this.setState({ access: false }); })
            }
            checkHeaderAccess();
        }
        else {
            this.setState({ access: true });
            this.fetchHeaders();
        }
    }

    sendRequestAccess = async () => {
        const info = localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        requestAccess({
            'username': sendInfo.username,
            'firstName': sendInfo.firstName,
            'lastName': sendInfo.lastName,
            'accounttypeUsers': sendInfo.accounttypeUsers,
            'businessname': sendInfo.businessname,
            'ADCode': sendInfo.ADCode,
            'address': sendInfo.address,
            'email': sendInfo.email,
            'phoneNumber': sendInfo.phonenumber,
            'userId': sendInfo.id,
            'screenType': 'CIPL Header',
        }).then(Response => {
            toast.success(Response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(err => {
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    };

    fetchHeaders = async () => {
        const deviceADcode = localStorage.getItem('adCodes');
        const userId = localStorage.getItem('userId');
        const getHeadersPayload = { deviceADcode, userId, mode: 'list-header' };

        try {
            const response = await ciplHeaders(getHeadersPayload)
            if (response?.data) {
                this.setState({ headers: response.data.data });
            }
        }
        catch (error) {
            this.setState({ headers: [] });
        };
    };

    toggleForm = (header = null, deleteHeader = false) => {
        this.setState({
            showForm: deleteHeader ? false : true,
            currentHeader: header,

        });
        if (!deleteHeader) {
            window.scrollTo(0, 0);
        }
        document.activeElement.blur();
    };

    handleFormSubmit = async (values, { resetForm }) => {
        const adCode = localStorage.getItem('adCodes');
        const userId = localStorage.getItem('userId');
        values["mode"] = this.state.currentHeader ? "update-header" : "create-header";
        values["userId"] = this.state.currentHeader ? this.state.currentHeader.userId : userId;
        values["deviceADcode"] = adCode;

        let response;
        try {
            response = await ciplHeaders(values);

            if (response?.data?.data) {
                this.setState({ showForm: false });
                this.fetchHeaders(); // Refresh headers list
                toast.success('Header saved successfully!');
                resetForm();
            }
        } catch (err) {
            // Safely access the error message
            const errorMessage = err?.response?.data?.message || "An unexpected error occurred.";

            // Display the error message using toast
            toast.error(errorMessage);
        }
    };

    closeDeleteWarning = () => {
        this.setState({ showDeleteWarning: false });
    }

    deleteHeader = async () => {
        const deleteHeaderPayload = {
            mode: "delete-header",
            id: this.state.currentHeader.id,
        };
        try {
            const response = await ciplHeaders(deleteHeaderPayload);
            if (response?.data) {
                this.fetchHeaders(); // Refresh headers list
                toast.success('Header deleted successfully!');
            }
        }
        catch (err) {
            toast.error(err?.response?.data?.message || "An unexpected error occurred.");
        }
        finally {
            this.closeDeleteWarning();
        }
    }

    handlePhoneNumberChange = (isEdited, phoneNumber, selectedCountry, setFieldValue) => {
        const formattedNumber = phoneNumber.startsWith(`+${selectedCountry.dialCode}`)
            ? phoneNumber
            : `+${selectedCountry.dialCode}${phoneNumber}`;

        const isValid = isValidNumber(formattedNumber, selectedCountry.iso2.toUpperCase());

        setFieldValue("phoneNumber", isValid ? formattedNumber : phoneNumber);
        this.setState({ showPhoneErr: !isValid, dialCode: selectedCountry.dialCode });
    };


    render() {
        const { showForm, headers, currentHeader } = this.state;

        return (
            <>
                {!this.state.access ? <div className="justify-content-center pt-2 main-contain-title">
                    <>
                        <p>This feature is restricted.</p>
                        <Button
                            onClick={() => this.sendRequestAccess()}
                        >Request Access for free</Button>
                    </>
                </div>
                    :
                    <>
                        <Modal isOpen={this.state.showDeleteWarning} toggle={this.closeDeleteWarning}>
                            <ModalHeader>Are you sure?</ModalHeader>
                            <ModalBody>
                                <div className="row d-flex justify-content-center py-2 px-4">
                                    This action can not be reversed. This header will be permamemtly deleted. Do you want to delete?
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" onClick={this.deleteHeader}>Delete</Button>
                                <Button color="secondary" onClick={this.closeDeleteWarning}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                        <div className="w-100 px-0 py-2">
                            {/* Form Section */}
                            {showForm && (
                                <Card>
                                    <CardHeader className="c-header">{currentHeader ? 'Edit Header' : 'Add New Header'}</CardHeader>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            id: currentHeader ? currentHeader.id : null,
                                            title: currentHeader ? currentHeader.title : '',
                                            address: currentHeader ? currentHeader.address : '',
                                            phoneNumber: currentHeader ? currentHeader.phoneNumber : '',
                                            email: currentHeader ? currentHeader.email : '',
                                            website: currentHeader ? currentHeader.website : '',
                                            tagLine: currentHeader ? currentHeader.tagLine : '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={this.handleFormSubmit}
                                    >
                                        {({ values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleSubmit,
                                            handleBlur,
                                            setFieldValue }) => (

                                            <CardBody>
                                                <Form>
                                                    <div className="row mb-3">
                                                        <div className="col-md-6">
                                                            <label htmlFor="title" className="form-label">Title Name</label>
                                                            <Field
                                                                maxLength="50"
                                                                type="text"
                                                                className="form-control"
                                                                id="title"
                                                                name="title"
                                                                placeholder="Enter Title Name"
                                                            />
                                                            <ErrorMessage name="title" component="div" className="text-danger" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="address" className="form-label">Address</label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                id="address"
                                                                name="address"
                                                                placeholder="Enter Address"
                                                            />
                                                            <ErrorMessage name="address" component="div" className="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-6">
                                                            <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                                            <PhoneInput
                                                                showSelectedDialCode={true}
                                                                containerClassName="intl-tel-input"
                                                                inputClassName="form-control"
                                                                ref={this.phoneNumberRef}
                                                                value={`${values.phoneNumber}` || ''}
                                                                onPhoneNumberChange={(isEdited, phoneNumber, selectedCountry) => this.handlePhoneNumberChange(isEdited, phoneNumber, selectedCountry, setFieldValue)}
                                                            />
                                                            <ErrorMessage name="phoneNumber" component="div" className="text-danger" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="email" className="form-label">Email</label>
                                                            <Field
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                name="email"
                                                                placeholder="Enter Email"
                                                            />
                                                            <ErrorMessage name="email" component="div" className="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-6">
                                                            <label htmlFor="website" className="form-label">Website</label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                id="website"
                                                                name="website"
                                                                placeholder="Enter Website"
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="tagLine" className="form-label">Tagline</label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                id="tagLine"
                                                                name="tagLine"
                                                                placeholder="Enter Tagline"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <button style={{ backgroundColor: "var(--main-bg-color-container-header)" }} type="submit" className="btn btn-primary">Save</button>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        )}
                                    </Formik>
                                </Card>
                            )}

                            {/* Header List Section */}
                            <Card>
                                {!showForm && <CardHeader className="c-header">Header</CardHeader>}
                                <CardBody>
                                    <div className="row my-3 align-items-center">
                                        <div className="col">
                                            <h6>Header List</h6>
                                        </div>
                                        <div className="col text-end">
                                            <style>{`
                                    .custom-btn {
                                        background-color: #f0f0f0;
                                        border-color: #f0f0f0;
                                        transition: background-color 0.3s ease;
                                        color: var(--main-bg-color-container-header);
                                    }
                                    .custom-btn:hover {
                                        background-color: var(--main-bg-color-container-header);
                                        border-color: var(--main-bg-color-container-header);
                                    }
                                `}</style>
                                            <Button className="custom-btn py-2 px-4" onClick={() => this.toggleForm(null, false)}>
                                                + Add Header
                                            </Button>
                                        </div>
                                    </div>

                                    {/* Table */}
                                    {headers.length > 0 ? <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-light">
                                                <tr>
                                                    <th style={{ width: "80%" }}>Header Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {headers.map((header) => (
                                                    <tr key={"header-" + header.title}>
                                                        <td>{header.title}</td>
                                                        <td>
                                                            <button
                                                                className="btn btn-outline-primary btn-sm me-2 bg-transparent border-0"
                                                                onClick={() => this.toggleForm(header, false,)}
                                                            >
                                                                <i className="fa fa-edit" style={{ fontSize: "20px", color: "blue" }}></i>
                                                            </button>
                                                            <button onClick={() => {
                                                                this.toggleForm(header, true);
                                                                this.setState({ showDeleteWarning: true })
                                                            }} className="btn btn-outline-danger btn-sm bg-transparent border-0">
                                                                <i className="fa fa-trash" style={{ fontSize: "20px", color: "red" }}></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                        : <p>No data available</p>}
                                </CardBody>
                            </Card>
                        </div>
                    </>
                }
            </>
        );
    }
}

export default Header;
