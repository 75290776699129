import React, { useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';

import PrintCipl from './PrintCiplPreview';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import download from "downloadjs";
import { toast } from 'react-toastify';

const tabStyle = {
  height: 800,
  maxHeight: 1000,
  // overflowY: "scroll",
};
export default function Print(props) {
  const componentRef = useRef();
  const [showPrintPopup, setShowPrintPopup] = useState(false);
  const [printOtherCipl, setPrintOtherCipl] = useState(false);
  const ciplTypes = { packingList: "Packing List", invoice: "Invoice" };

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const handlePrint = async (printBothCipl) => {
    const deviceADcode = localStorage.getItem('adCodes');
    const userId = localStorage.getItem('userId');
    const printCiplDataFromStorage = localStorage.getItem('cipl_print');
    if (!printCiplDataFromStorage) {
      toast.error("No data available to print")
    }
    const printCiplData = JSON.parse(printCiplDataFromStorage);
    const { REACT_APP_URL } = process.env;
    const BASE_URL = REACT_APP_URL
      ? REACT_APP_URL
      : "https://www.addressdynamic.com/api";
    const payload = {
      "id": printCiplData.id,
      userId,
      deviceADcode,
      "isPackingList": printCiplData.ciplType === ciplTypes.packingList,
      isDownload: true
    }
    try {
      const downloadCiplUrl = "/v2/cipl/send-cipl-email.php";
      const firstCiplDownload = fetch(BASE_URL + downloadCiplUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const secondCiplDownload = fetch(BASE_URL + downloadCiplUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...payload, isPackingList: !payload.isPackingList }),
      });

      // Wait for both API calls to complete
      const [response, secondResponse] = await Promise.all([firstCiplDownload, secondCiplDownload]);

      const downloadFileName1 = `${payload.isPackingList ? (printCiplData.packingListNo || printCiplData.packingListNumber) : (printCiplData.invoiceNo || printCiplData.invoiceNumber)}-cipl-${printCiplData.ciplType.toLowerCase()}.pdf`
      const downloadFileName2 = `${!payload.isPackingList ? (printCiplData.packingListNo || printCiplData.packingListNumber) : (printCiplData.invoiceNo || printCiplData.invoiceNumber)}-cipl-${printCiplData.ciplType === ciplTypes.packingList ? ciplTypes.invoice.toLowerCase() : ciplTypes.packingList.toLowerCase()}.pdf`

      // Handle the first API response (for PDF download)
      if (!response.ok) {
        throw new Error("Failed to fetch the PDF");
      }
      const blob1 = await response.blob();  // Get the first PDF as a Blob
      download(blob1, downloadFileName1, "application/pdf");

      // Handle the second API response
      if (!secondResponse.ok && printBothCipl) {
        throw new Error("Failed to fetch the PDF");
      }
      if (printBothCipl) {
        const blob2 = await secondResponse.blob();  // Get the second PDF as a Blob
        download(blob2, downloadFileName2, "application/pdf");
      }


    } catch (error) {
      console.error("Error:", error);
    }


  }

  return (
    <>
      <Modal isOpen={showPrintPopup} toggle={() => setShowPrintPopup(!showPrintPopup)}>
        <ModalHeader>Print options</ModalHeader>
        <ModalBody>
          <p>Do you also want to print for {props.ciplType === ciplTypes.invoice ? ciplTypes.packingList : ciplTypes.invoice}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => {
            setPrintOtherCipl(true);
            setShowPrintPopup(false);
            setTimeout(() => {
              handlePrint(true);
            }, 1000);
          }}>Download both</Button>
          <Button color="secondary" onClick={() => {
            setShowPrintPopup(false)
            handlePrint(false);
          }
          }>Download this one</Button>
        </ModalFooter>
      </Modal>


      <div style={tabStyle}>
        <Button onClick={props.goBack} style={{ position: "relative", left: "20px", top: "20px", backgroundColor: "var(--main-bg-color-container-header)" }}>Back</Button>
        {props.size === '150*100mm' &&
          <PrintCipl ciplType={props.ciplType} printOtherCipl={printOtherCipl} goBack={props.goBack} ref={componentRef} />
        }

        <div className="d-flex justify-content-center mb-4" id="no-print" >
          <button className="btn btn-submit ml-2 mt-3 mb-4" onClick={() => {
            if (!printOtherCipl)
              setShowPrintPopup(true)
            else
              handlePrint()
          }}>Print this out!</button>

        </div>
      </div >
    </>
  )
}
