import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { checkScreenAccess, ciplBill, ciplHeaders, ciplProductDb, requestAccess } from "../../../api/Form";
import { toast } from "react-toastify";
import Print from "./PrintCipl";
import { withRouter } from 'react-router-dom';
import CreateCiplForm from "./components/CreateCiplForm";

const ciplTypes = { packingList: "Packing List", invoice: "Invoice" };
const discountTypes = { absolute: "Absolute value", percentage: "Percentage" };
const discountTypeDrpdwn = [{ label: "Absolute value", value: "Absolute value", }, { label: "Percentage", value: "Percentage", }];
class CreateCIPLDetails extends Component {
    state = {
        access: false,
        headers: [],
        headresListOfObj: [],
        selectedHeader: { value: "", id: null },
        products: [],
        ciplType: "",
        categoryItems: [],
        print: false,
        currentCipl: null,
        isEditMode: false,
        isViewOnly: false,
        isCiplSaved: false,
        useCipl: false,
        generatedInvoiceNumber: null,
        generatedPackingListNumber: null,
        generatedRefNumber: null,

    };

    async componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('auth'));
        if (userInfo && userInfo.accounttypeUsers !== "Admin") {
            const screenAccessPayload = {
                userId: userInfo.id,
                screenType: "Create CIPL"
            };

            try {
                const response = await checkScreenAccess(screenAccessPayload);
                if (response.status)
                    this.setState({ access: true });
            } catch (error) {
                this.setState({ access: false });
                return;
            }
        }
        else {
            this.setState({ access: true });
        }
        this.setState({ generatedInvoiceNumber: this.generateInvoiceNumber(), generatedRefNumber: this.generateRefNumber(), generatedPackingListNumber: this.generatePackingListNumber() });

        const deviceADcode = localStorage.getItem('adCodes');
        const userId = localStorage.getItem('userId');
        // to fetch headers to create cipl
        const fetchHeaders = () => {
            const getHeadersPayload = { deviceADcode, userId, mode: 'list-header' };
            ciplHeaders(getHeadersPayload)
                .then((response) => {
                    const headersList = response.data.data.map((header) => {
                        return { title: header.title, id: header.id }
                    });
                    const headerListForDrpdwn = headersList.map((header) => {
                        return { value: header.title, label: header.title, id: header.id };
                    })
                    this.setState({ headers: headerListForDrpdwn, headresListOfObj: response.data.data });
                })
                .catch((error) => {
                });
        };

        // to fetch cipl to edit it
        const getCiplDetails = (ciplId) => {
            const payload = { id: ciplId, "mode": "edit-cipl-bill", userId, deviceADcode }
            ciplBill(payload).then((response) => {
                const responseObj = response.data.data;
                const packingListType = responseObj.ciplType;
                this.setState({ currentCipl: responseObj, selectedHeader: { value: responseObj.header, id: responseObj.headerId, label: responseObj.value }, ciplType: packingListType })
                // set header data to print cipl
                const ciplPrintFromStorage = localStorage.getItem("cipl_print");
                const ciplPrintData = ciplPrintFromStorage ? JSON.parse(ciplPrintFromStorage) : {};
                const ciplPrintDataUpdated = { ...ciplPrintData, headerData: { ...responseObj.headerData } };
                localStorage.setItem("cipl_print", JSON.stringify(ciplPrintDataUpdated));
            }).catch((err) => {
                toast.error(err)
            })
        }

        const params = new URLSearchParams(window.location.search);
        const ciplIdFromUrl = params.get("cipl-id");
        const isViewOnlyFromurl = params.get("view");
        const isUseCiplFromurl = params.get("use-cipl");
        if (isViewOnlyFromurl) {
            this.setState({ isViewOnly: true, isCiplSaved: true })
        }
        if (ciplIdFromUrl) {
            this.setState({ isEditMode: true });
            getCiplDetails(ciplIdFromUrl);
        }
        if (isUseCiplFromurl) {
            this.setState({ useCipl: true });
        }

        fetchHeaders();

    }

    handleProductChange = (index, field, value) => {
        const products = [...this.state.products];
        products[index][field] = value;
        this.setState({ products });
    };

    addProduct = () => {
        const newProduct = { id: Date.now(), name: "", weight: "", price: "", code: "" };
        this.setState((prevState) => ({
            products: [...prevState.products, newProduct],
        }));
    };

    removeProduct = (index) => {
        const products = [...this.state.products];
        products.splice(index, 1);
        this.setState({ products });
    };

    calculateTotalWeight = (productData) => {
        return productData.reduce((total, product) => total + parseFloat(product.weight || 0), 0);
    };
    generateInvoiceNumber() {
        return `CI${Math.floor(1000000 + Math.random() * 9000000)}`;
    }
    generateRefNumber() {
        return `G${Math.floor(1000000000 + Math.random() * 9000000000)}`
    }
    generatePackingListNumber() {
        return `PL${Math.floor(1000000 + Math.random() * 9000000)}`;
    }

    // Callback function to update the state
    updateState = (updatedState) => {
        this.setState(updatedState);
    };

    sendRequestAccess = async () => {
        const info = localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        requestAccess({
            'username': sendInfo.username,
            'firstName': sendInfo.firstName,
            'lastName': sendInfo.lastName,
            'accounttypeUsers': sendInfo.accounttypeUsers,
            'businessname': sendInfo.businessname,
            'ADCode': sendInfo.ADCode,
            'address': sendInfo.address,
            'email': sendInfo.email,
            'phoneNumber': sendInfo.phonenumber,
            'userId': sendInfo.id,
            'screenType': 'Create CIPL',
        }).then(Response => {
            toast.success(Response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(err => {
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    };

    render() {
        const { access, products, ciplType, headers, headresListOfObj, selectedHeader, print, currentCipl, isEditMode, isViewOnly, isCiplSaved, useCipl, generatedInvoiceNumber, generatedRefNumber, generatedPackingListNumber } = this.state;

        const validationSchema = Yup.object().shape({
            date: Yup.date().required("Date is required"),
            containerNumber: Yup.string().required("Container Number is required"),
            billNumber: Yup.string().required("Bill Number is required"),
            sealNumber: Yup.string().required("Seal Number is required"),
            mot: Yup.string().required("MOT is required"),
            customerNumber: Yup.string().required("Customer Number is required"),
            consignee: Yup.string().required("Consignee name is required"),
            consigneeAddress: Yup.string().required("Consignee address is required"),
            shipTo: Yup.string().required("Ship To Name is required"),
            shipToAddress: Yup.string().required("Ship To Address is required"),
            hblRef: Yup.string().required("HBL/REF is required"),
            packingListNumber: Yup.string()
                .nullable()
                .test('at-least-one', 'Packing List Number is required', function (value) {
                    const { invoiceNo } = this.parent; // Access the other field via `this.parent`
                    if (!value && !invoiceNo) {
                        return false; // Fail validation if neither field is filled
                    }
                    return true; // Pass validation if at least one field is filled
                }),
            invoiceNo: Yup.string()
                .nullable()
                .test('at-least-one', 'Invoice Number is required', function (value) {
                    const { packingListNumber } = this.parent; // Access the other field via `this.parent`
                    if (!value && !packingListNumber) {
                        return false; // Fail validation if neither field is filled
                    }
                    return true; // Pass validation if at least one field is filled
                }),
            incoterm: Yup.string().required("Incoterm is required"),
            invoiceTerms: Yup.string().required("Invoice terms is required"),
            productToAdd: Yup.object().shape({
                weight: Yup.number()
                    .typeError("Weight must be a number")
                    .positive("Weight must be greater than zero")
                ,
                price: Yup.number()
                    .typeError("Price must be a number")
                    .positive("Price must be greater than zero")
                ,
                code: Yup.string()
                    .matches(/^[0-9-]+$/, "Only numbers and hyphens are allowed"),
                discount: Yup.number()
                    .typeError("Discount must be a number")
                    .positive("Discount must be greater than zero")
            }),
            products: Yup.array().of(
                Yup.object().shape({
                    weight: Yup.number()
                        .typeError("Weight must be a number")
                        .positive("Weight must be greater than zero")
                        .required("Weight is required"),
                    price: Yup.number()
                        .typeError("Price must be a number")
                        .positive("Price must be greater than zero")
                        .required("Price is required"),
                    code: Yup.string()
                        .matches(/^[0-9-]+$/, "Only numbers and hyphens are allowed"),
                    discount: Yup.number()
                        .typeError("Discount must be a number")
                        .positive("Discount must be greater than zero")
                })
            ),
        });

        if (!access) {
            return <div className="justify-content-center pt-2 main-contain-title">
                <p>This feature is restricted.</p>
                <Button onClick={() => this.sendRequestAccess()} >
                    Request Access for free
                </Button>
            </div>
        }

        if (print) {
            return <Print ciplType={ciplType} goBack={() => this.setState({ print: false })} size='150*100mm'
                reset={() => this.setState({ print: false })} />
        }

        return (
            <div className="w-100 px-0 py-2">
                <Card>
                    <CardHeader className="c-header">Create New CIPL</CardHeader>
                    <CardBody>
                        <>
                            <div className="d-flex flex-wrap align-items-end">
                                <div className="col-lg-9 col-md-8 col-sm-12">
                                    <FormGroup>
                                        <Label for="headerSelect">Select Header</Label>
                                        <Input
                                            disabled={isViewOnly}
                                            type="select"
                                            name="header"
                                            id="headerSelect"
                                            value={selectedHeader?.value || ""} // Ensure controlled input
                                            onChange={(e) => {
                                                const selectedOption = headers.find(
                                                    (header) => header.value === e.target.value
                                                );
                                                this.setState({ selectedHeader: selectedOption });
                                                // set header data to print cipl
                                                const ciplPrintFromStorage = localStorage.getItem("cipl_print");
                                                const ciplPrintData = ciplPrintFromStorage ? JSON.parse(ciplPrintFromStorage) : {};
                                                const ciplPrintDataUpdated = { ...ciplPrintData, headerData: { ...headresListOfObj.find(headerItem => headerItem.id === selectedOption.id) } };
                                                localStorage.setItem("cipl_print", JSON.stringify(ciplPrintDataUpdated));
                                            }}
                                        >
                                            <option disabled value="">Select a header</option>
                                            {headers.map((header) => (
                                                <option key={header.id} value={header.value}>
                                                    {header.label}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <Button
                                            disabled={isEditMode}
                                            onClick={(e) => {
                                                this.setState({ ciplType: ciplTypes.packingList })
                                                e.target.blur();
                                            }}
                                            className={`btn ${ciplType === ciplTypes.packingList ? "btn-primary" : "btn-secondary"} mb-2`}
                                            style={{ flex: "1 1 48%", minWidth: "48%", marginRight: "5px" }}
                                        >
                                            Packing List
                                        </Button>
                                        <Button
                                            disabled={isEditMode}
                                            onClick={(e) => {
                                                this.setState({ ciplType: ciplTypes.invoice });
                                                e.target.blur();
                                            }}
                                            className={`btn ${ciplType === ciplTypes.invoice ? "btn-primary" : "btn-secondary"} mb-2`}
                                            style={{ flex: "1 1 48%", minWidth: "48%" }}
                                        >
                                            Invoice
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            {(selectedHeader.value === "" || (currentCipl === null && isEditMode) || ciplType === "") ? <></>
                                :

                                <Container className="py-3">
                                    <h4>{ciplType}</h4>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            date: currentCipl && !useCipl ? currentCipl.date : "",
                                            containerNumber: currentCipl && !useCipl ? currentCipl.containerNo : "",
                                            billNumber: currentCipl && !useCipl ? currentCipl.billNo : "",
                                            sealNumber: currentCipl && !useCipl ? currentCipl.sealNo : "",
                                            mot: currentCipl && !useCipl ? currentCipl.modeOfTransport : "",
                                            customerNumber: currentCipl && !useCipl ? currentCipl.customerNo : "",
                                            consignee: currentCipl && !useCipl ? currentCipl.consignee : "",
                                            consigneeAddress: currentCipl && !useCipl ? currentCipl.consigneeAddress : "",
                                            shipTo: currentCipl && !useCipl ? currentCipl.shipTo : "",
                                            shipToAddress: currentCipl && !useCipl ? currentCipl.shipToAddress : "",
                                            hblRef: currentCipl && !useCipl ? currentCipl.hblRef : generatedRefNumber,
                                            packingListNumber: currentCipl && !useCipl ? currentCipl.packingListNo : generatedPackingListNumber,
                                            invoiceNo: currentCipl && !useCipl ? currentCipl.invoiceNo : generatedInvoiceNumber,
                                            totalWeight: currentCipl ? currentCipl.totalWeight : "",
                                            incoterm: currentCipl && !useCipl ? currentCipl.incoterm : "",
                                            invoiceTerms: currentCipl && !useCipl ? currentCipl.invoiceTerms : "",
                                            comments: currentCipl ? currentCipl.comments : "",
                                            products: currentCipl?.productDetails ? JSON.parse(currentCipl.productDetails) : products,
                                            currency: currentCipl ? currentCipl.currency : "",
                                            discountType: currentCipl?.discountType === discountTypes.percentage ? discountTypeDrpdwn[1] : discountTypeDrpdwn[0],
                                            productToAdd: {
                                                name: "",
                                                weight: "",
                                                price: "",
                                                discount: "",
                                                finalPrice: "",
                                                code: "",
                                                disableHsCode: false
                                            },
                                            printHsCode: currentCipl ? currentCipl.printHsCode : 1
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={async (values) => {
                                            if (values.products.length === 0) {
                                                toast.error("Add minimum one product to proceed");
                                                return;
                                            }

                                            if (values.products.find((product) => product.name.trim() === "")) {
                                                toast.error("Please provide product description to all added products");
                                                return;
                                            }
                                            const deviceADcode = localStorage.getItem("adCodes");
                                            const userId = localStorage.getItem("userId");
                                            const keyMapping = {
                                                billNumber: "billNo",
                                                sealNumber: "sealNo",
                                                containerNumber: "containerNo",
                                                customerNumber: "customerNo",
                                                products: "productDetails",
                                                packingListNumber: "packingListNo",
                                                mot: "modeOfTransport"
                                            };
                                            const ciplDetailPayload = Object.keys(values).reduce((acc, key) => {
                                                const newKey = keyMapping[key] || key;
                                                acc[newKey] = values[key];
                                                return acc;
                                            }, {});
                                            ciplDetailPayload["header"] = selectedHeader.value;
                                            ciplDetailPayload["headerId"] = `${selectedHeader.id}`;
                                            ciplDetailPayload["userId"] = currentCipl ? currentCipl.userId : userId;
                                            ciplDetailPayload["deviceADCode"] = deviceADcode;
                                            ciplDetailPayload["mode"] = isEditMode && !useCipl ? "update-cipl-bill" : "create-cipl-bill";
                                            ciplDetailPayload["ciplType"] = ciplType;
                                            ciplDetailPayload["discountType"] = ciplDetailPayload["discountType"].value || ciplDetailPayload["discountType"];
                                            if (currentCipl && isEditMode && !useCipl) {
                                                ciplDetailPayload["id"] = currentCipl.id;
                                            }
                                            delete ciplDetailPayload["productToAdd"]

                                            try {
                                                const response = await ciplBill(ciplDetailPayload);
                                                if (response?.data) {
                                                    this.setState({ isCiplSaved: true, currentCipl: response.data.data })
                                                    toast.success(`CIPL saved successfully`, {
                                                        position: toast.POSITION.TOP_RIGHT
                                                    })
                                                    const { location, history } = this.props;
                                                    const basePath = location.pathname.split('/').slice(0, -1).join('/');
                                                    history.push(`${basePath}/cipl`)
                                                }
                                            }
                                            catch (error) {
                                                toast.error(error?.response?.data?.message || "An unexpected error occurred.");
                                            }
                                        }}
                                    >
                                        {({ handleSubmit, handleBlur, validateForm, errors, touched, setFieldValue, values, validateField }) => (
                                            <CreateCiplForm
                                                formProps={{ handleSubmit, handleBlur, validateForm, errors, touched, setFieldValue, values, validateField }}
                                                states={{ isViewOnly, isCiplSaved, currentCipl, ciplType }}
                                                functions={{ calculateTotalWeight: this.calculateTotalWeight }}
                                                updateParentState={this.updateState}
                                            />
                                        )}
                                    </Formik>
                                </Container>
                            }
                        </>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default withRouter(CreateCIPLDetails);
